import {
  templatesOptions,
  addDefaultOptions,
} from 'design-comuni-plone-theme/config/Blocks/ListingOptions';

export const AddRicercaAlfabeticaTemplate = (
  schema,
  formData,
  position = 0,
  intl,
) => {
  let pos = position;

  pos = addDefaultOptions(schema, formData, pos);

  pos = templatesOptions(schema, formData, intl, [], {}, pos);

  return pos;
};
