import React, { Component } from 'react';
import Slider from 'react-slick';

import { FontAwesomeIcon } from 'design-comuni-plone-theme/components/ItaliaTheme';

import Image from 'design-comuni-plone-theme/components/Image/Image';

export default class AsNavFor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      autoplay: false,
      imagePreview: null,
    };
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  play() {
    this.slider1.slickPlay();
    this.setState({ autoplay: true });
  }

  pause() {
    this.slider1.slickPause();
    this.setState({ autoplay: false });
  }

  onClickHandler(id) {
    this.props.onClickChange(id);
  }

  render() {
    const searchResults = this.props.searchResults;

    return (
      <div className="slider-wrapper">
        {this.state.autoplay === false && (
          <button className="autoplay-button" onClick={this.play}>
            <FontAwesomeIcon icon="play" className="autoplay-button-icon" />{' '}
            play
          </button>
        )}
        {this.state.autoplay === true && (
          <button className="autoplay-button" onClick={this.pause}>
            <FontAwesomeIcon icon="pause" className="autoplay-button-icon" />{' '}
            pause
          </button>
        )}
        <Slider
          asNavFor={this.state.nav2}
          ref={(slider) => (this.slider1 = slider)}
          arrow={true}
          className="larger-slider"
          autoplaySpeed={2000}
          slidesToScroll={1}
          infinite={true}
          autoplay={this.state.autoplay === true ? true : false}
        >
          {searchResults.map((result) => {
            const id = result['@id'];
            const { title, peso } = result;
            return (
              <div key={id}>
                <Image image={id} onClick={() => this.onClickHandler(id)} />
                <div className="picture-description-container">
                  <p>
                    <strong>{title}</strong> - {peso} KG
                  </p>
                </div>
              </div>
            );
          })}
        </Slider>
        <Slider
          asNavFor={this.state.nav1}
          ref={(slider) => (this.slider2 = slider)}
          slidesToShow={searchResults.length < 7 ? searchResults.length : 7}
          swipeToSlide={true}
          focusOnSelect={true}
          className="preview-slider"
          autoplay={this.state.autoplay}
        >
          {searchResults.map((result) => {
            const id = result['@id'];
            return (
              <div key={id}>
                <Image image={id} />
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}
