import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import PropTypes from 'prop-types';

import { Form, Segment } from 'semantic-ui-react';
import { ObjectBrowserWidget, TextWidget } from '@plone/volto/components';

const Sidebar = ({ data, block, onChangeBlock, selected = 0, setSelected }) => {
  const intl = useIntl();
  return (
    <Form>
      <Segment.Group raised form>
        <header className="header pulled">
          <h2>{intl.formatMessage(messages.specialistiBlockTitle)}</h2>
        </header>
        <TextWidget
          id="ArgumentsTitle"
          title={intl.formatMessage(messages.titleString)}
          value={data.text}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              text: value,
            });
          }}
        />
        <ObjectBrowserWidget
          id={'ObjectBrowserWidget'}
          title={intl.formatMessage(messages.chooseDoctorString)}
          required={true}
          value={data.medici}
          widgetOptions={{
            pattern_options: { selectableTypes: ['Persona'] },
          }}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              medici: value,
            });
          }}
        />
      </Segment.Group>
    </Form>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  block: PropTypes.string,
  onChangeBlock: PropTypes.func,
  selected: PropTypes.any,
  setSelected: PropTypes.func,
};
export default Sidebar;

const messages = defineMessages({
  specialistiBlockTitle: {
    id: 'specialistiBlockTitle',
    defaultMessage: 'Blocco specialisti',
  },
  titleString: {
    id: 'titleString',
    defaultMessage: 'Titolo',
  },
  chooseDoctorString: {
    id: 'chooseDoctorString',
    defaultMessage: 'Scegli i medici',
  },
});
