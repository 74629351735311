import React from 'react';

import { getBaseUrl } from '@plone/volto/helpers';

import Body from './Body';

const Edit = ({
  data,
  block,
  onChangeBlock,
  selected,
  pathname,
  properties,
}) => {
  return (
    <div className="biblioteca-medica-container bandi-search public-ui">
      <Body
        data={data}
        path={getBaseUrl(pathname)}
        inEditMode={true}
        onChangeBlock={onChangeBlock}
        properties={properties}
      />
    </div>
  );
};

export default Edit;
