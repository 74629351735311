import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { UniversalLink } from '@plone/volto/components';
import { PagePlaceholderTitle } from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const DocumentoQualita = ({ content, location }) => {
  const intl = useIntl();

  let attachmentsName = [];
  for (let i = 1; i < 16; i++) {
    attachmentsName.push(`attachment${i}`);
  }

  let attachments = [];

  for (let a = 1; a < 16; a++) {
    if (content['attachment' + a] !== null) {
      attachments.push(content['attachment' + a]);
    }
  }

  return (
    <div
      className="documento-qualita-view ui container px-4 mb-5"
      id="page-document"
    >
      <div className="documento-qualita-page mb-5">
        <div className="page-header box-shadow PageHeaderWrapper mb-4">
          <div className="title-description-wrapper">
            <PagePlaceholderTitle content={content}>
              <h1 className="mb-3">{content?.title}</h1>
            </PagePlaceholderTitle>
          </div>
        </div>

        {content?.description && (
          <div className="description-section">
            <h4 className="label">
              {intl.formatMessage(messages.description)}
            </h4>
            <p className="description">{content?.description}</p>
          </div>
        )}

        {content?.structures?.length > 0 && (
          <div className="documento-qualita-info-item">
            <h4 className="label">{intl.formatMessage(messages.struttura)}</h4>
            {content?.structures.map((structure) => (
              <p className="book-info-content" key={structure.token}>
                {structure.title}
              </p>
            ))}
          </div>
        )}
        {content?.categories?.title && (
          <div className="documento-qualita-info-item">
            <h4 className="label">{intl.formatMessage(messages.tipo)}</h4>
            <p className="book-info-content">{content.categories.title}</p>
          </div>
        )}
        {content?.interest_area && (
          <div className="documento-qualita-info-item">
            <h4 className="label">
              {intl.formatMessage(messages.areaInteresse)}
            </h4>
            <p className="book-info-content">{content.interest_area.title}</p>
          </div>
        )}
        {content?.useful_for?.title && (
          <div className="documento-qualita-info-item">
            <h4 className="label">{intl.formatMessage(messages.utilePer)}</h4>
            <p className="book-info-content">{content.useful_for.title}</p>
          </div>
        )}
        {content?.revision && (
          <div className="documento-qualita-info-item">
            <h4 className="label">{intl.formatMessage(messages.revision)}</h4>
            <p className="book-info-content">{content.revision}</p>
          </div>
        )}
        {content?.startDate && (
          <div className="documento-qualita-info-item">
            <h4 className="label">{intl.formatMessage(messages.date)}</h4>
            <p className="book-info-content">{content.startDate}</p>
          </div>
        )}
        <h4 className="label">{intl.formatMessage(messages.download)}</h4>
        <div className="download-button mb-4">
          <UniversalLink
            href={content['@id'] + '/@@download/file'}
            className="button"
            download
          >
            {intl.formatMessage(messages.downloadPDF)}
          </UniversalLink>
        </div>

        {attachments.length > 0 && (
          <h4 className="label">{intl.formatMessage(messages.attachments)}</h4>
        )}
        <div className="attachment-buttons">
          {attachments?.map((file) => (
            <div className="download-button">
              <div className="attachment-filename">{file.filename}</div>
              <div className="attachment-link">
                <UniversalLink href={file.download} className="button" download>
                  {intl.formatMessage(messages.downloadPDF)}
                </UniversalLink>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DocumentoQualita;

const messages = defineMessages({
  description: {
    id: 'description',
    defaultMessage: 'Descrizione',
  },
  struttura: {
    id: 'strutture',
    defaultMessage: 'Strutture',
  },
  tipo: {
    id: 'tipo',
    defaultMessage: 'Tipo',
  },
  areaInteresse: {
    id: 'areaInteresse',
    defaultMessage: "Area d'interesse",
  },
  utilePer: {
    id: 'utilePer',
    defaultMessage: 'Utile per',
  },
  revision: {
    id: 'revision',
    defaultMessage: 'Revisione',
  },
  date: {
    id: 'date',
    defaultMessage: 'Data',
  },
  download: {
    id: 'download',
    defaultMessage: 'Download',
  },
  attachments: {
    id: 'attachments',
    defaultMessage: 'Allegati',
  },
  downloadPDF: {
    id: 'downloadPDF',
    defaultMessage: 'Scarica PDF',
  },
});
