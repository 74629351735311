import React from 'react';
import { useIntl } from 'react-intl';

import { Table } from 'design-react-kit/dist/design-react-kit';

const TabellaNeonati = ({ searchResults, onClickChange, messages }) => {
  const intl = useIntl();
  return (
    <div>
      {searchResults.length > 0 && (
        <Table>
          <thead>
            <tr>
              <th scope="col" className="py-10 gy-10 col-2 px-5">
                <p>{intl.formatMessage(messages.tabellaHeaderNome)}</p>
              </th>
              <th scope="col" className="py-10 gy-10 px-5">
                <p>{intl.formatMessage(messages.tabellaHeaderPeso)}</p>
              </th>
              <th scope="col" className="py-10 gy-10 px-4">
                <p>{intl.formatMessage(messages.tabellaHeaderSesso)}</p>
              </th>
              <th scope="col" className="py-10 gy-10 px-4">
                <p>{intl.formatMessage(messages.tabellaHeaderData)}</p>
              </th>
              <th scope="col" className="py-10 gy-10 px-4">
                <p>{intl.formatMessage(messages.tabellaHeaderOra)}</p>
              </th>
              <th scope="col" className="py-10 gy-10 px-5">
                <p>{intl.formatMessage(messages.tabellaHeaderFoto)}</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {searchResults.map((result) => {
              const { title, peso, sesso } = result;
              const id = result['@id'];
              const data_e_ora_di_nascita = result.data_e_ora_di_nascita.split(
                'T',
              );
              const data_di_nascita = data_e_ora_di_nascita[0];
              const ora_di_nascita = data_e_ora_di_nascita[1];

              const [year, month, day] = data_di_nascita.split('-');

              const [hours, minutes] = ora_di_nascita.split(':');

              const formattedPeso = () => {
                if (peso.length === 1) {
                  return `${peso}.00`;
                } else if (peso.length > 1) {
                  return `${peso}00`;
                }
              };

              // const ora_di_nascita = result.data_e_ora_di_nascita;
              return (
                <tr key={id}>
                  <td className="col-2 px-5">
                    <p>{title}</p>
                  </td>
                  <td className="col-2 px-5">
                    <p>{formattedPeso().slice(0, 4)}</p>
                  </td>
                  <td className="col-1 px-4">
                    <p>{sesso}</p>
                  </td>
                  <td className="col-2 px-4">
                    <p>
                      {day}-{month}-{year}
                    </p>
                  </td>
                  <td className="col-2 px-4">
                    <p>
                      {hours}:{minutes}
                    </p>
                  </td>
                  <td className="col-3 px-5">
                    <button onClick={() => onClickChange(id)}>
                      {intl.formatMessage(messages.fotoButton)}
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default TabellaNeonati;
