import React from 'react';

import Block from './Block';

const View = (props) => {
  const { data } = props;
  return (
    <div className="full-width container specialisti">
      <div className="specialisti-block-container container">
        <div className="specialisti-block-title">
          <h3>{data.text}</h3>
        </div>
        <div className="specialisti-block-content container view-container"></div>
        <Block {...props} />
      </div>
    </div>
  );
};

export default View;
