import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { PagePlaceholderTitle } from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const LibroView = ({ content, location }) => {
  const intl = useIntl();
  return (
    <div className="libro-page-container ui container px-4" id="page-document">
      <div className="libro-page">
        <div className="page-header box-shadow PageHeaderWrapper mb-4">
          <div className="title-description-wrapper">
            <PagePlaceholderTitle content={content}>
              <h1 className="mb-3">{content?.title}</h1>
            </PagePlaceholderTitle>

            <div className="book-info">
              {content.sottotitolo && (
                <h3 className="subtitle">{content?.sottotitolo}</h3>
              )}
              {content.description && (
                <div className="description-section">
                  <h4 className="label">
                    {intl.formatMessage(messages.description)}
                  </h4>
                  <p className="description">{content?.description}</p>
                </div>
              )}
              <h4 className="label">{intl.formatMessage(messages.author)}</h4>
              <p className="book-info-content">{content?.autore}</p>
              <h4 className="label">{intl.formatMessage(messages.year)}</h4>
              <p className="book-info-content">{content?.anno}</p>
              <h4 className="label">{intl.formatMessage(messages.place)}</h4>
              <p className="book-info-content">{content?.luogo}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LibroView;

const messages = defineMessages({
  description: {
    id: 'description',
    defaultMessage: 'Description',
  },
  author: {
    id: 'author',
    defaultMessage: 'Autore',
  },
  year: {
    id: 'year',
    defaultMessage: 'Anno',
  },
  place: {
    id: 'place',
    defaultMessage: 'Luogo',
  },
});
