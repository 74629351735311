import CustomIcon from '../../../CustomIcon/CustomIcon';

const CategoryButton = ({
  bodyCategories,
  onClickHandler,
  clickButton,
  resetButtonFilter,
}) => {
  const icons = [
    'brain',
    'eye',
    'ear-solid',
    'tooth',
    'heart',
    'lungs',
    'stomach-solid',
    'kidneys-solid',
    'venus-mars',
    'bone',
    'hand-solid',
    'droplet-solid',
  ];

  bodyCategories.map((bodyCategory) => {
    for (let i = 0; i < 12; i++) {
      bodyCategories[i].icon = icons[i];
    }
    return bodyCategories;
  });

  return (
    <div className="categories-section-buttons">
      {bodyCategories.map((bodyCategory) => {
        const { label, value, icon } = bodyCategory;

        const highlightButton = clickButton === label;

        return (
          <div className="category-button-container" key={value}>
            <button
              className={`${
                highlightButton
                  ? 'category-button-active button category-button'
                  : 'button category-button'
              } `}
              key={value}
              onClick={() => {
                onClickHandler(value);
                if (clickButton === value) {
                  resetButtonFilter();
                }
              }}
            >
              <div className="category-button-content">
                <div className="category-button-icon-section">
                  <div className="category-button-icon-container">
                    <CustomIcon icon={icon} />
                  </div>
                </div>
                <div className="category-button-text">{label}</div>
              </div>
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default CategoryButton;
