import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import { searchContent, resetSearchContent } from '@plone/volto/actions';

import { Container, Button } from 'design-react-kit/dist/design-react-kit';
import Image from 'design-comuni-plone-theme/components/Image/Image';

import { FontAwesomeIcon } from 'design-comuni-plone-theme/components/ItaliaTheme';

import {
  TextFilter,
  DateFilter,
} from 'design-comuni-plone-theme/components/ItaliaTheme/Blocks/Common/SearchFilters';

import TabellaNeonati from './Blocks/TabellaNeonati';
import Slider from './Blocks/Slider';
import MessageContainer from '../../MessageContainer/MessageContainer';
import TablePagination from '../../TablePagination/TablePagination';

const Body = ({
  data,
  inEditMode,
  path,
  onChangeBlock,
  properties,
  disablePast,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [searchField, setSearchField] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filters, setFilters] = useState({ search: '', dates: {} });

  // Redux - loading
  const isResultLoading = useSelector((state) => state.search.loading);

  // Redux - error

  const error = useSelector((state) => state.search.error);
  const noError = error === null;

  const onChangeSearch = (e, data) => {
    setSearchField(data);
  };

  const onChangeDate = (startDateFilter, endDateFilter) => {
    if (startDate === '') {
      setStartDate(startDateFilter);
    } else if (endDate === '') {
      setEndDate(endDateFilter);
    }
  };

  const applyFilters = () => {
    setFilters({
      ...filters,
      search: searchField,
      dates: { startDate, endDate },
    });
    setCurrentPage(1);
  };

  const noFiltersSet = filters.search === '' || filters.dates === {};

  const searchResults = useSelector((state) => state.search.items);
  const totResults = useSelector((state) => state.search.total);

  // Attiva modalità slideshow

  const [slideshowState, setSlideshowState] = useState(true);
  const [bSize, setbSize] = useState(8);
  const [tableState, setTableState] = useState(false);

  const activateView = () => {
    setCurrentPage(1);
    setSlideshowState(!slideshowState);
    setTableState(!tableState);
    setbSize(slideshowState ? 6 : totResults.length);
  };
  // Attiva modalità tabella

  //* Immagine preview quando si clicca su slideshow

  const [previewImage, setPreviewImage] = useState(null);

  const openPreviewImage = (id) => {
    setPreviewImage(id);
  };

  // ** PAGINATION ** \\

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totResults / bSize); i++) {
    pageNumbers.push(i);
  }

  const [currentPage, setCurrentPage] = useState(pageNumbers[0]);

  const lastPage = pageNumbers.length;

  const lastResIndex = bSize * currentPage;

  const firstResIndex = bSize * (currentPage - 1) + 1;

  const bStart = (currentPage - 1) * bSize;

  //** REDUX STATE FUNCTIONS **/

  useEffect(() => {
    dispatch(
      searchContent(path, {
        'path.depth': 1,
        SearchableText: filters.search,
        // 'data_e_ora_di_nascita.query:list:date': startDate,
        // 'data_e_ora_di_nascita.query:list:date': endDate,
        // 'data_e_ora_di_nascita.range': 'min:max',

        metadata_fields: ['peso', 'data_e_ora_di_nascita', 'sesso'],
        b_size: bSize,
        b_start: bStart,
        sort_on: 'sortable_title',
        sort_order: 'ascending',
      }),
    );
    return () => {
      dispatch(resetSearchContent());
    };
  }, [dispatch, path, filters, bSize, bStart, startDate, endDate]);
  return (
    <Container>
      {previewImage !== null && (
        <div className="preview-image-container">
          <Image image={previewImage} />
          <a
            href="/"
            onClick={(click) => {
              click.preventDefault();
              setPreviewImage(null);
            }}
          >
            <FontAwesomeIcon icon="times" />
          </a>
        </div>
      )}
      <div className="rounded container">
        <div className="d-flex justify-content-center">
          <div className="d-flex search-container align-items-center justify-content-center flex-wrap">
            <form
              className="search-form"
              onSubmit={(event) => {
                event.preventDefault();
                applyFilters();
              }}
            >
              <div className="search-box-container">
                <TextFilter
                  value={searchField}
                  onChange={(e, data) => onChangeSearch(e, data)}
                  placeholder={intl.formatMessage(
                    messages.textFilterPlaceholder,
                  )}
                  type="submit"
                />
              </div>
              <div className="select-box-container">
                <DateFilter
                  id="datepicker"
                  onChange={(id, dates) => {
                    const startDateFilter = moment(dates.start)
                      .locale('it')
                      .format()
                      .slice(0, 19);
                    const endDateFilter = moment(dates.end)
                      .locale('it')
                      .format('')
                      .slice(0, 19);
                    onChangeDate(startDateFilter, endDateFilter);
                  }}
                />
              </div>
              <div className="search-button-container container">
                <Button className="search-button" type="submit">
                  {intl.formatMessage(messages.searchButton)}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="results-container container">
        {slideshowState && !isResultLoading && noError && (
          <div className="slideshow-neonati-container">
            <Slider
              searchResults={searchResults}
              onClickChange={openPreviewImage}
              totResults={totResults}
            />
          </div>
        )}

        {slideshowState && !isResultLoading && noError && (
          <div className="activate-table-section">
            <a
              href="/"
              onClick={(click) => {
                click.preventDefault();
                activateView();
              }}
            >
              Mostra la tabella <FontAwesomeIcon icon="arrow-right" />
            </a>
          </div>
        )}

        {tableState && !isResultLoading && noError && (
          <div className="tabella-neonati">
            <div className="tabella-neonati-container container">
              <TabellaNeonati
                searchResults={searchResults}
                onClickChange={openPreviewImage}
                messages={messages}
              />
            </div>

            {searchResults.length === 0 && noError && !isResultLoading && (
              <MessageContainer messageType="no-res" />
            )}
            {/* Mostra durante caricamento */}
            {isResultLoading && <MessageContainer messageType="loading" />}

            {/* Mostra quando si verifica un errore */}
            {!noError && <MessageContainer messageType="error" />}

            <div className="pagination-container">
              <div className="activate-slideshow-section">
                <a
                  href="/"
                  onClick={(click) => {
                    click.preventDefault();
                    activateView();
                  }}
                >
                  <FontAwesomeIcon icon="arrow-left" /> Mostra la gallery
                </a>
              </div>
              <TablePagination
                searchResults={searchResults}
                firstResIndex={firstResIndex}
                lastResIndex={lastResIndex}
                totResults={totResults}
                noFiltersSet={noFiltersSet}
                pageNumbers={pageNumbers}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                lastPage={lastPage}
              />
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default Body;

const messages = defineMessages({
  textFilterPlaceholder: {
    id: 'textFilterPlaceholder',
    defaultMessage: 'Digita un nome o una parola chiave',
  },
  dataInizioPlaceholder: {
    id: 'dataInizioPlaceholder',
    defaultMessage: 'Data inizio',
  },
  dataFinePlaceholder: {
    id: 'dataFinePlaceholder',
    defaultMessage: 'Data fine',
  },
  searchButton: {
    id: 'searchButton',
    defaultMessage: 'Cerca',
  },
  mostraTabellaButton: {
    id: 'mostraTabellaButton',
    defaultMessage: 'Mostra la tabella',
  },
  mostraGalleryButton: {
    id: 'mostraGalleryButton',
    defaultMessage: 'Mostra la gallery',
  },
  tabellaHeaderNome: {
    id: 'tabellaHeaderNome',
    defaultMessage: 'Nome',
  },
  tabellaHeaderPeso: {
    id: 'tabellaHeaderPeso',
    defaultMessage: 'Peso (Kg)',
  },
  tabellaHeaderSesso: {
    id: 'tabellaHeaderSesso',
    defaultMessage: 'Sesso',
  },
  tabellaHeaderData: {
    id: 'tabellaHeaderData',
    defaultMessage: 'Data di nascita',
  },
  tabellaHeaderOra: {
    id: 'tabellaHeaderOra',
    defaultMessage: 'Ora di nascita',
  },
  tabellaHeaderFoto: {
    id: 'tabellaHeaderFoto',
    defaultMessage: 'Foto',
  },
  fotoButton: {
    id: 'fotoButton',
    defaultMessage: 'Vedi le foto',
  },
});
