// CUSTOMIZATION
// - cambiato nome area riservata
// - componente UniversalLink da plone per logout
// - aggiunto login con authentik

/* eslint-disable react-hooks/exhaustive-deps */
/**
 * ArLogin component.
 * @module components/ItaliaTheme/Header/HeaderSlim/ArLogin
 */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import jwtDecode from 'jwt-decode';
import {
  Row,
  Col,
  DropdownMenu,
  DropdownToggle,
  LinkList,
  LinkListItem,
  UncontrolledDropdown,
  Button,
} from 'design-react-kit/dist/design-react-kit';
import { UniversalLink } from '@plone/volto/components';

import { getUser, logout, purgeMessages } from '@plone/volto/actions';

import { BodyClass } from '@plone/volto/helpers';

import {
  Icon,
  UserLoggedMenu,
  LoginButton,
} from 'design-comuni-plone-theme/components/ItaliaTheme';

import config from '@plone/volto/registry';

const messages = defineMessages({
  arLogin: {
    id: "Accedi all'area personale",
    defaultMessage: 'Area riservata',
  },
  arLogout: {
    id: 'arLogout',
    defaultMessage: 'Esci',
  },
  authentikLoginLabel: {
    id: 'authentikLoginLabel',
    defaultMessage: 'Area riservata (autenticazione federata)',
  },
});

const ArLogin = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const userId = useSelector((state) =>
    state.userSession.token ? jwtDecode(state.userSession.token).sub : null,
  );

  const userLogged = useSelector((state) => state.users.user);
  const userLoggedSt = useSelector((state) => state.users);

  useEffect(() => {
    if (!userLoggedSt?.get?.loading && userId) {
      dispatch(getUser(userId));
    }
  }, [userId]);

  const doLogout = () => {
    dispatch(logout());
    dispatch(purgeMessages());
  };

  let rolesBodyClasses = [];
  // eslint-disable-next-line no-unused-expressions
  userLogged?.roles?.forEach((role) => {
    rolesBodyClasses.push(`role-${role.toLowerCase()}`);
  });

  if (!userLogged?.roles || userLogged?.roles?.length === 0) {
    rolesBodyClasses.push('no-user-roles');
  }

  return (
    <>
      {/* add user roles classes to body */}
      {rolesBodyClasses?.length > 0 && (
        <BodyClass className={rolesBodyClasses.join(' ')} />
      )}

      {config.settings.siteProperties?.arLoginUrl ? (
        <>
          {!userId ? (
            // not logged, desktop
            <>
              <div className="d-none d-lg-flex items-center">
                <LoginButton>
                  <span className="rounded-icon">
                    <Icon
                      color="primary"
                      icon="it-user"
                      padding={false}
                      size=""
                    />
                  </span>
                  <span className="d-none d-lg-block">
                    {intl.formatMessage(messages.arLogin)}
                  </span>
                </LoginButton>
                <Button
                  className="btn-icon"
                  color="inherit"
                  href="/oidc/login?came_from=/area-riservata"
                  icon={false}
                  size="full"
                  tag="a"
                >
                  <span className="rounded-icon">
                    <Icon
                      color="primary"
                      icon="it-user"
                      padding={false}
                      size=""
                    />
                  </span>
                  <span className="d-none d-lg-block">
                    {intl.formatMessage(messages.authentikLoginLabel)}
                  </span>
                </Button>
              </div>
              <UncontrolledDropdown tag="div" nav className="d-lg-none">
                <DropdownToggle
                  aria-haspopup
                  caret
                  color="secondary"
                  nav
                  className="btn-icon"
                >
                  <span className="rounded-icon">
                    <Icon
                      color="primary"
                      icon="it-user"
                      size=""
                      aria-label={intl.formatMessage({ id: 'Login' })}
                      aria-hidden="false"
                    />
                  </span>
                  <Icon
                    color=""
                    icon="it-expand"
                    padding={false}
                    size=""
                    className="ml-0"
                  />
                </DropdownToggle>
                <DropdownMenu flip tag="div">
                  <Row tag="div">
                    <Col
                      size="12"
                      tag="div"
                      widths={['xs', 'sm', 'md', 'lg', 'xl']}
                    >
                      <LinkList tag="div">
                        <li>
                          <LoginButton className="list-item d-flex items-center text-left font-weight-light">
                            <Icon
                              color="primary"
                              icon="it-user"
                              size="sm"
                              left
                              className="flex-0 mr-2"
                            />
                            <span className="flex-0 mr-0">
                              {intl.formatMessage(messages.arLogin)}
                            </span>
                          </LoginButton>
                        </li>
                        <LinkListItem divider />
                        <LinkListItem
                          href="/oidc/login?came_from=/area-riservata"
                          title={intl.formatMessage(
                            messages.authentikLoginLabel,
                          )}
                          className="d-flex items-center"
                          tag="a"
                        >
                          <Icon
                            color="primary"
                            icon="it-user"
                            size="sm"
                            left
                            className="flex-0 mr-2"
                          />
                          <span className="flex-0 mr-0">
                            {intl.formatMessage(messages.authentikLoginLabel)}
                          </span>
                        </LinkListItem>
                      </LinkList>
                    </Col>
                  </Row>
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          ) : (
            // logged
            <>
              {/* dropdown */}
              <UncontrolledDropdown nav tag="div">
                <DropdownToggle
                  aria-haspopup
                  caret
                  color="secondary"
                  nav
                  className="btn-icon"
                >
                  <span class="rounded-icon">
                    <Icon color="primary" icon="it-user" size="" />
                  </span>
                  <span class="d-none d-lg-block">
                    {userLogged.fullname
                      ? userLogged.fullname
                      : userLogged.username}
                  </span>
                  <Icon color="" icon="it-expand" padding={false} size="" />
                </DropdownToggle>
                <DropdownMenu flip tag="div">
                  <Row tag="div">
                    <Col
                      size="12"
                      tag="div"
                      widths={['xs', 'sm', 'md', 'lg', 'xl']}
                    >
                      <LinkList tag="div">
                        <UserLoggedMenu />
                        <LinkListItem divider tag="a" />
                        <LinkListItem
                          href={
                            config.settings.siteProperties.arLogoutUrl || '/'
                          }
                          title={intl.formatMessage(messages.arLogout)}
                          tag={UniversalLink}
                          onClick={() => {
                            if (!config.settings.siteProperties.arLogoutUrl) {
                              doLogout();
                            }
                          }}
                          className="logout"
                        >
                          <Icon
                            color="primary"
                            icon="sign-out-alt"
                            size="sm"
                            left
                          />
                          <span>{intl.formatMessage(messages.arLogout)}</span>
                        </LinkListItem>
                      </LinkList>
                    </Col>
                  </Row>
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          )}
        </>
      ) : null}
    </>
  );
};

export default ArLogin;
