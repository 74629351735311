import React from 'react';
import { Container, Row, Col } from 'design-react-kit/dist/design-react-kit';

import SubsectionTitleEdit from './Blocks/SubsectionTitleEdit/SubsectionTitleEdit';

import ComeRaggiungerciData from './Data';
import ContactCard from './Blocks/ContactCard/ContactCard';
import IconsList from './Blocks/IconsList/IconsList';

import { SidebarPortal } from '@plone/volto/components';

const Edit = (props) => {
  const { block, data, onChangeBlock, selected } = props;

  return (
    <div className="full-width contact-info-container">
      <div className="edit-subcontainer">
        <Container>
          <Row className="mt-5 gx-1">
            <Col lg="6" md="12" sm="12" className="col">
              <div className="contact-info-content container">
                <div className="subsection-title-container">
                  <SubsectionTitleEdit
                    data={data}
                    fieldName="title1"
                    selected={true}
                    block={block}
                    onChangeHandler={onChangeBlock}
                    placeholder="add title"
                    className="title-container-1"
                  />
                </div>

                <IconsList {...props} />
              </div>
            </Col>
            <Col lg="6" sm="12" md="12">
              <div className="contact-info-content contact-card container">
                <div className="subsection-title-container">
                  <SubsectionTitleEdit
                    data={data}
                    fieldName="title2"
                    selected={true}
                    block={block}
                    onChangeHandler={onChangeBlock}
                    placeholder="add title"
                    className="title-container-2"
                  />
                </div>

                <ContactCard {...props} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <SidebarPortal selected={selected}>
        <ComeRaggiungerciData
          key={block}
          {...props}
          data={data}
          block={block}
          onChangeBlock={onChangeBlock}
        />
      </SidebarPortal>
    </div>
  );
};

export default Edit;
