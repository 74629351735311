import React from 'react';

import redraft from 'redraft';
import config from '@plone/volto/registry';

const SubsectionTitle = (props) => {
  const { title } = props;

  return (
    <div className="subsection-title-container">
      <div className="title-container-1">
        {redraft(
          title,
          config.settings.richtextViewSettings.ToHTMLRenderers,
          config.settings.richtextViewSettings.ToHTMLOptions,
        )}
      </div>
    </div>
  );
};

export default SubsectionTitle;
