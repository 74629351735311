/**
 * Faq actions.
 * @module actions/resetState/resetState
 */

import { RESET_STATE } from '../../constants/ActionTypes';

export const resetState = () => {
  return { type: RESET_STATE };
};
