/*
Customizzazione per inserire avviso nell'header della pagina Servizi
*/

//import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'design-react-kit/dist/design-react-kit';
import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';

const PageHeaderExtend = ({ content }) => {
  return (
    <div className="page-header-extend mt-5">
      {content?.avviso && (
        <Alert className="page-header-alert" color="info">
          <div className="alert-content">
            <div className="alert-icon">
              <Icon icon="bullhorn" />
            </div>
            <div className="alert-text">{content.avviso}</div>
          </div>
        </Alert>
      )}
    </div>
  );
};
export default PageHeaderExtend;

PageHeaderExtend.propTypes = {
  params: PropTypes.shape({
    content: PropTypes.object,
    readingtime: PropTypes.string,
    showreadingtime: PropTypes.bool,
    imageinheader: PropTypes.bool,
    imageinheader_field: PropTypes.string,
    showdates: PropTypes.bool,
    showtassonomiaargomenti: PropTypes.bool,
  }),
};
