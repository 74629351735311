import React from 'react';

import { SidebarPortal } from '@plone/volto/components';
import { TextEditorWidget } from 'design-comuni-plone-theme/components/ItaliaTheme/manage/Widgets/TextEditorWidget';

import Sidebar from './Sidebar';
import CardEdit from './Blocks/CardEdit/CardEdit';

const Edit = (props) => {
  const { block, data, onChangeBlock, selected } = props;
  const showTitle = data.show_block_title;
  return (
    <div className="full-width servizi-base-container">
      <div className="edit-subcontainer">
        <div className="servizi-base-content">
          {showTitle && (
            <div className="title-section">
              <h4>
                <TextEditorWidget
                  data={data}
                  fieldName="blockTitle"
                  selected={true}
                  block={block}
                  onChangeBlock={(v) => {
                    onChangeBlock(data, {
                      ...data,
                      blockTitle: v.blockTitle,
                    });
                  }}
                  className="text-editor blockTitle"
                />
              </h4>
            </div>
          )}
          <div className="card-container">
            <CardEdit
              data={data}
              fieldName="card1"
              selected={true}
              block={block}
              onChangeHandler={onChangeBlock}
              placeholder="Inserisci testo"
              className="textEditor1"
              icon={data.icon1}
              title={data.title1}
              titleLink={data.titleLink1}
            />
            {data.title2 && (
              <CardEdit
                data={data}
                fieldName="card2"
                selected={false}
                block={block}
                onChangeHandler={onChangeBlock}
                placeholder="Inserisci testo"
                className="textEditor2"
                icon={data.icon2}
                title={data.title2}
                titleLink={data.titleLink2}
              />
            )}
            {data.title3 && (
              <CardEdit
                data={data}
                fieldName="card3"
                selected={false}
                block={block}
                onChangeHandler={onChangeBlock}
                placeholder="Inserisci testo"
                className="textEditor3"
                icon={data.icon3}
                title={data.title3}
                titleLink={data.titleLink3}
              />
            )}
            {data.title4 && (
              <CardEdit
                data={data}
                fieldName="card4"
                selected={false}
                block={block}
                onChangeHandler={onChangeBlock}
                placeholder="Inserisci testo"
                className="textEditor4"
                icon={data.icon4}
                title={data.title4}
                titleLink={data.titleLink4}
              />
            )}
          </div>
        </div>

        <SidebarPortal selected={selected}>
          <Sidebar
            key={block}
            {...props}
            data={data}
            block={block}
            onChangeBlock={onChangeBlock}
          />
        </SidebarPortal>
      </div>
    </div>
  );
};

export default Edit;
