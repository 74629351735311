import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardReadMore,
} from 'design-react-kit/dist/design-react-kit';
import { UniversalLink } from '@plone/volto/components';
import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';

const Block = ({ data }) => {
  const intl = useIntl();

  const dataIsTrue = data.medici !== undefined;
  const medici = data.medici;
  return (
    <div className="result-grid container">
      <div className="row card-container">
        {medici?.map((medico) => {
          return (
            <div className="col-lg-4 col-md-6 col-sm-12" key={medico['@id']}>
              <Card classname="card-bg card-specialista" tag="div">
                <CardBody tag="div">
                  <CardTitle tag="h3">
                    <span className="title-icon-section">
                      <Icon icon="user-md" />
                    </span>
                    {dataIsTrue && (
                      <span className="title-text-section">{medico.title}</span>
                    )}
                  </CardTitle>
                  <CardText tag="p">
                    <div className="card-text-reparto">
                      {intl.formatMessage(messages.repartoString)}:{' '}
                      <UniversalLink href={medico.division_url}>
                        {medico.division}
                      </UniversalLink>
                    </div>

                    <div className="card-text-dipartimento">
                      {intl.formatMessage(messages.dipartimentoString)}:{' '}
                      <UniversalLink href={medico.department_url}>
                        {medico.department}
                      </UniversalLink>
                    </div>
                  </CardText>
                </CardBody>
                {dataIsTrue && (
                  <CardReadMore
                    tag={UniversalLink}
                    text={intl.formatMessage(messages.schedaLink)}
                    iconName="it-arrow-right"
                    href={medico['@id']}
                  />
                )}
              </Card>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Block;

const messages = defineMessages({
  repartoString: {
    id: 'repartoString',
    defaultMessage: 'Reparto',
  },
  dipartimentoString: {
    id: 'dipartimentoString',
    defaultMessage: 'Dipartimento',
  },
  schedaLink: {
    id: 'schedaLink',
    defaultMessage: 'Vedi la scheda',
  },
});
