import React from 'react';

import {
  Pager,
  PagerItem,
  PagerLink,
} from 'design-react-kit/dist/design-react-kit';

import CustomIcon from '../CustomIcon/CustomIcon';

const TablePagination = ({
  searchResults,
  firstResIndex,
  lastResIndex,
  totResults,
  noFiltersSet,
  pageNumbers,
  currentPage,
  lastPage,
  setCurrentPage,
}) => {
  const inactivePrevButton = currentPage === 1 || pageNumbers.length === 1;
  const inactiveForwButton =
    currentPage === lastPage ||
    pageNumbers.length === 1 ||
    pageNumbers.length === 0;

  //pagination for large number of pages

  const largePageNumber = pageNumbers.length > 10;

  const range = (start, end) => {
    return [...Array(end - start).keys()].map((el) => el + start);
  };

  const pagesCount = pageNumbers.length;

  const pagesCutCount = 5;
  const ceiling = Math.ceil(pagesCutCount / 2);
  const floor = Math.floor(pagesCutCount / 2);

  const getPagesCut = ({ pagesCount, pagesCutCount, currentPage }) => {
    if (pagesCount < pagesCutCount) {
      return { start: 1, end: pagesCount + 1 };
    } else if (currentPage >= 1 && currentPage <= ceiling) {
      return { start: 1, end: pagesCutCount + 1 };
    } else if (currentPage + floor >= pagesCount) {
      return { start: pagesCount - pagesCutCount + 1, end: pagesCount + 1 };
    } else {
      return { start: currentPage - ceiling + 1, end: currentPage + floor + 1 };
    }
  };

  const pagesCut = getPagesCut({ pagesCount, pagesCutCount, currentPage });
  const pages = range(pagesCut.start, pagesCut.end);

  // ** Pagination functions ** \\
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const goBackwards = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else {
      return;
    }
  };

  const goForwards = () => {
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
    } else {
      return;
    }
  };

  return (
    <div className="pagination-section">
      <div className="pagination-details">
        <p>
          {searchResults.length === 0 ? 0 : firstResIndex} -{' '}
          {lastResIndex < totResults ? lastResIndex : totResults} di{' '}
          {noFiltersSet ? totResults : searchResults.length} items{' '}
        </p>
      </div>

      <div className="pagination-nav">
        <Pager>
          <ul className="pagination">
            {largePageNumber && (
              <PagerItem>
                <PagerLink
                  className={
                    inactivePrevButton
                      ? 'inactive-arrow-link'
                      : 'active-arrow-link'
                  }
                  onClick={(click) => {
                    click.preventDefault();
                    paginate(1);
                  }}
                >
                  <CustomIcon icon="angle-double-left" className="skip-to" />
                </PagerLink>
              </PagerItem>
            )}
            <PagerItem>
              <PagerLink
                className={
                  inactivePrevButton
                    ? 'inactive-arrow-link'
                    : 'active-arrow-link'
                }
                onClick={(click) => {
                  click.preventDefault();
                  goBackwards();
                }}
              >
                <CustomIcon icon="angle-left" className="skip-to" />
              </PagerLink>
            </PagerItem>

            {largePageNumber && (
              <div className="large-pagination">
                {currentPage > ceiling && (
                  <PagerItem className="more-pages-indicator">...</PagerItem>
                )}
                {pages.map((a) => {
                  const highlightButton = currentPage === a;
                  return (
                    <PagerItem
                      key={a}
                      className={highlightButton ? 'page-item-active' : ''}
                    >
                      <PagerLink
                        href="#"
                        className={highlightButton ? 'page-link-active' : ''}
                        onClick={(click) => {
                          click.preventDefault();
                          paginate(a);
                        }}
                      >
                        {a}
                      </PagerLink>
                    </PagerItem>
                  );
                })}
                {currentPage < pagesCount - Math.ceil(5 / 2) + 1 && (
                  <PagerItem className="more-pages-indicator">...</PagerItem>
                )}
              </div>
            )}
            {!largePageNumber &&
              pageNumbers.map((number) => {
                const highlightButton = currentPage === number;
                return (
                  <PagerItem
                    key={number}
                    className={highlightButton ? 'page-item-active' : ''}
                  >
                    <PagerLink
                      href="#"
                      className={highlightButton ? 'page-link-active' : ''}
                      onClick={(click) => {
                        click.preventDefault();
                        paginate(number);
                      }}
                    >
                      {number}
                    </PagerLink>
                  </PagerItem>
                );
              })}
            <PagerItem>
              <PagerLink
                className={
                  inactiveForwButton
                    ? 'inactive-arrow-link'
                    : 'active-arrow-link'
                }
                onClick={(click) => {
                  click.preventDefault();
                  goForwards();
                }}
              >
                <CustomIcon icon="angle-right" className="skip-to" />
              </PagerLink>
            </PagerItem>
            {largePageNumber && (
              <PagerItem>
                <PagerLink
                  className={
                    inactiveForwButton
                      ? 'inactive-arrow-link'
                      : 'active-arrow-link'
                  }
                  onClick={(click) => {
                    click.preventDefault();
                    paginate(pagesCount);
                  }}
                >
                  <CustomIcon icon="angle-double-right" className="skip-to" />
                </PagerLink>
              </PagerItem>
            )}
          </ul>
        </Pager>
      </div>
    </div>
  );
};

export default TablePagination;
