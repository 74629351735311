const schemaRicercaModuli = (props) => {
  return {
    title: 'Ricerca moduli',
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Colonna',
        fields: ['searchOnSite'],
      },
    ],
    properties: {
      searchOnSite: {
        title: 'Effettuare la ricerca su tutto il sito?',
        type: 'boolean',
      },
    },
  };
};

export default schemaRicercaModuli;
