import cx from 'classnames';

import { Row, Col, Container } from 'design-react-kit/dist/design-react-kit';

import AlphabetNavbar from '../../AlphabetNavbar/AlphabetNavbar';
import ResultCard from '../../ResultCard/ResultCard';

const RicercaAlfabeticaTemplate = (props) => {
  const {
    title,
    show_block_bg,
    addFilters,
    items,
    additionalFilters = [],
  } = props;

  let filterLetter = additionalFilters
    .filter((filter) => {
      return filter.i === 'startswith';
    })
    ?.map((filter) => {
      return filter.v;
    });

  const onClickFilter = (letter) => {
    addFilters([
      {
        i: 'startswith',
        o: 'plone.app.querystring.operation.string.is',
        v: letter,
      },
    ]);
  };

  const resetLetterFilter = () => {
    addFilters([]);
  };

  return (
    <div className="blocco-alfabeto">
      <Container className="px-4">
        {title && (
          <Row className="template header">
            {title && (
              <Col md="12">
                <h2
                  className={cx('mb-4', {
                    'mt-5': !show_block_bg,
                  })}
                >
                  {title}
                </h2>
              </Col>
            )}
          </Row>
        )}

        <div className="alphabet-navbar-section mb-5">
          <AlphabetNavbar
            onClickHandler={onClickFilter}
            filterLetter={filterLetter[0]}
            resetLetterFilter={resetLetterFilter}
          />
        </div>
        <Row className="items">
          <div className="result-card-container">
            <ResultCard searchResults={items} />
          </div>
          {items.length === 0 && (
            <div className="no-results">Nessun risultato</div>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default RicercaAlfabeticaTemplate;
