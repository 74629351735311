// /**
//  * Faq reducer.
//  * @module reducers/faq/faq
//  */

import { map } from 'lodash';
import config from '@plone/volto/registry';

import { LOAD_EXCEL, RESET_STATE } from '../../constants/ActionTypes';

const initialState = {
  error: null,
  items: [],
  loaded: false,
  loading: false,
};

// /**
//  * Faq reducer.
//  * @function  faq
//  * @param {Object} state Current state.
//  * @param {Object} action Action to be handled.
//  * @returns {Object} New state.
//  */
export default function loadExcel(state = initialState, action = {}) {
  switch (action.type) {
    case `${LOAD_EXCEL}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${LOAD_EXCEL}_SUCCESS`:
      return {
        ...state,
        error: null,
        items: map(action.result.items, (item) => ({
          ...item,
          '@id': item['@id'].replace(config.settings.apiPath, ''),
        })),
        loaded: true,
        loading: false,
        message: action.result.message,
      };
    case `${LOAD_EXCEL}_FAIL`:
      return {
        ...state,
        error: action.error,
        items: [],
        loading: false,
        loaded: false,
      };
    case RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
