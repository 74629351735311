const schemaComeRaggiungerci = (props) => {
  return {
    title: 'Come raggiungerci',
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Colonna',
        fields: [
          'title',
          'info',
          'contact1',
          'contact2',
          'contactIcon',
          'contact3',
          'contactLink',
        ],
      },
      {
        id: 'item1',
        title: 'Info 1',
        fields: ['icon', 'text', 'link', 'linktext'],
      },
      {
        id: 'item2',
        title: 'Info 2',
        fields: ['icon2', 'text2', 'link2', 'linktext2'],
      },
      {
        id: 'item3',
        title: 'Info 3',
        fields: ['icon3', 'text3', 'link3', 'linktext3'],
      },
    ],
    properties: {
      title: {
        title: 'Titolo contatto',
        widget: 'text',
      },
      info: {
        title: 'Info aggiuntive',
        widget: 'text',
      },
      contact1: {
        title: 'Telefono',
        widget: 'text',
      },
      contact2: {
        title: 'Email',
        widget: 'text',
      },
      contactIcon: {
        title: 'Icona contatto',
        widget: 'icon',
      },
      contact3: {
        title: 'Contatto',
        widget: 'text',
      },
      contactLink: {
        title: 'Link modulo di contatto',
        widget: 'object_browser',
        allowExternals: true,
        mode: 'link',
      },
      icon: {
        title: 'Icona',
      },
      text: {
        title: 'Testo',
        widget: 'text',
      },
      link: {
        title: 'Link',
        widget: 'object_browser',
        allowExternals: true,
        mode: 'link',
      },
      linktext: {
        title: 'Testo link',
        widget: 'text',
      },
      icon2: {
        title: 'Icona',
        widget: 'icon',
      },
      text2: {
        title: 'Testo',
        widget: 'description',
      },
      link2: {
        title: 'Link',
        widget: 'object_browser',
        allowExternals: true,
        mode: 'link',
      },
      linktext2: {
        title: 'Testo link',
        widget: 'text',
      },
      icon3: {
        title: 'Icona',
        widget: 'icon',
      },
      text3: {
        title: 'Testo',
        widget: 'text',
      },
      link3: {
        title: 'Link',
        widget: 'object_browser',
        allowExternals: true,
        mode: 'link',
      },
      linktext3: {
        title: 'Testo link',
        widget: 'text',
      },
    },
  };
};

export default schemaComeRaggiungerci;
