/**
 * Album view component.
 * @module components/theme/View/AlbumView
 */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { defineMessages, useIntl } from 'react-intl';

import { searchContent, resetSearchContent } from '@plone/volto/actions';

import {
  PageHeaderNav,
  PagePlaceholderTitle,
  RichText,
  richTextHasContent,
  PageHeaderTassonomiaArgomenti,
  Sharing,
  Actions,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import Image from '@plone/volto/components/theme/Image/Image';
import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';

import ResultTable from './Components/ResultTable';

import MessageContainer from '../../MessageContainer/MessageContainer';
import TablePagination from '../../TablePagination/TablePagination';

/**
 * Album view component class.
 * @function IncarichiView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */

const IncarichiView = ({ content, location }) => {
  //* utils
  const intl = useIntl();
  const dispatch = useDispatch();

  //* Filters state

  const [searchField, setSearchField] = useState('');

  const onChangeSearch = (string) => {
    setSearchField(string.target.value);
    setCurrentPage(1);
  };

  const noFiltersSet = searchField === '';

  const searchResults = useSelector((state) => state.search.items);
  const totResults = useSelector((state) => state.search.total);

  // Redux - loading
  const isResultLoading = useSelector((state) => state.search.loading);

  // Redux - error

  const error = useSelector((state) => state.search.error);
  const noError = error === null;

  //* Pagination **/

  const [currentPage, setCurrentPage] = useState(1);
  const bSize = 10;
  const bStart = (currentPage - 1) * bSize;

  //* Calcolo numero pagine

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totResults / bSize); i++) {
    pageNumbers.push(i);
  }

  const lastPage = pageNumbers.length;

  const lastResIndex = bSize * currentPage;

  const firstResIndex = bSize * (currentPage - 1) + 1;

  //* Hook useEffect per parametri ricerca incarichi */

  useEffect(() => {
    dispatch(
      searchContent(location.pathname, {
        'path.depth': 1,
        metadata_fields: ['Oggetto', 'Estremi'],
        SearchableText: searchField,

        b_size: bSize,
        b_start: bStart,
        sort_on: 'sortable_title',
        sort_order: 'ascending',
      }),
    );
    return () => {
      dispatch(resetSearchContent());
    };
  }, [dispatch, location.pathname, searchField, bSize, bStart]);

  // * Header const
  const rightHeaderHasContent =
    content.image?.scales ||
    richTextHasContent(content?.info_testata) ||
    content.mostra_navigazione ||
    content?.tassonomia_argomenti?.length > 0 ||
    content.mostra_bottoni_condivisione;
  return (
    <>
      <div
        className="patologie-page-container ui container px-4"
        id="page-document"
      >
        <div className="patologie-page">
          {/*-----Testata-----*/}
          <div className="page-header box-shadow PageHeaderWrapper mb-4">
            <div className="row">
              <div
                className={cx('title-description-wrapper', {
                  'col-lg-6': rightHeaderHasContent,
                  'col-lg-12': !rightHeaderHasContent,
                })}
              >
                <PagePlaceholderTitle content={content}>
                  <h1 className="mb-3">{content?.title}</h1>
                </PagePlaceholderTitle>

                <p className="description">{content?.description}</p>

                <div className="search-box-group header-text">
                  <div className="input-group-icon">
                    <Icon icon="search" />
                  </div>
                  <input
                    className="search-box"
                    type="search"
                    placeholder={intl.formatMessage(
                      messages.textFieldPlaceholder,
                    )}
                    onChange={onChangeSearch}
                    value={searchField}
                  />
                </div>
              </div>
              {rightHeaderHasContent && (
                <div className="col-lg-4 offset-lg-2">
                  {content.mostra_bottoni_condivisione && (
                    <div className="px-4 mb-4">
                      <Sharing url={content['@id']} title={content.title} />
                      <Actions url={content['@id']} title={content.title} />
                    </div>
                  )}
                  {content.image?.scales && (
                    <div className="header-image px-4 mb-3">
                      <Image
                        image={content.image}
                        alt={content.title}
                        maxSize={300}
                        key={content.image?.download}
                      />
                    </div>
                  )}
                  {richTextHasContent(content.info_testata) && (
                    <div className="header-infos px-4 mb-5">
                      <RichText serif={false} content={content.info_testata} />
                    </div>
                  )}

                  {content.mostra_navigazione && (
                    <PageHeaderNav
                      content={content}
                      title={intl.formatMessage(messages.inThisSection)}
                    />
                  )}
                  {content?.tassonomia_argomenti?.length > 0 && (
                    <div className="px-4">
                      <PageHeaderTassonomiaArgomenti content={content} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {/* Tabella */}
          {!isResultLoading && noError && (
            <div className="document-table">
              <div className="result-table-container mt-5">
                <ResultTable tableData={searchResults} messages={messages} />
              </div>
              <TablePagination
                searchResults={searchResults}
                firstResIndex={firstResIndex}
                lastResIndex={lastResIndex}
                totResults={totResults}
                noFiltersSet={noFiltersSet}
                pageNumbers={pageNumbers}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                lastPage={lastPage}
              />
            </div>
          )}

          {searchResults.length === 0 && noError && !isResultLoading && (
            <MessageContainer messageType="no-res" />
          )}
          {/* Mostra durante caricamento */}
          {isResultLoading && <MessageContainer messageType="loading" />}

          {/* Mostra quando si verifica un errore */}
          {!noError && <MessageContainer messageType="error" />}
        </div>
      </div>
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
IncarichiView.propTypes = {
  /**
   * Content of the object
   */
  content: PropTypes.shape({
    /**
     * Title of the object
     */
    title: PropTypes.string,
    /**
     * Description of the object
     */
    description: PropTypes.string,
    /**
     * Child items of the object
     */
    items: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * Title of the item
         */
        title: PropTypes.string,
        /**
         * Description of the item
         */
        description: PropTypes.string,
        /**
         * Url of the item
         */
        url: PropTypes.string,
        /**
         * Image of the item
         */
        image: PropTypes.object,
        /**
         * Image caption of the item
         */
        image_caption: PropTypes.string,
        /**
         * Type of the item
         */
        '@type': PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default IncarichiView;

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
  inThisSection: {
    id: 'In this section',
    defaultMessage: 'In questa sezione',
  },
  modified: {
    id: 'modified',
    defaultMessage: 'Ultimo aggiornamento',
  },
  textFieldPlaceholder: {
    id: 'textFieldPlaceholder',
    defaultMessage: 'Digita la parola chiave',
  },
  tabellaHeaderSoggetto: {
    id: 'tabellaHeaderSoggetto',
    defaultMessage: 'Soggetto',
  },
  tabellaHeaderEstremiConferimento: {
    id: 'tabellaHeaderEstremiConferimento',
    defaultMessage: 'Estremi atto di conferimento n.data',
  },
  tabellaHeaderOggetto: {
    id: 'tabellaHeaderOggetto',
    defaultMessage: "Oggetto dell'incarico",
  },
  tabellaHeaderPeriodo: {
    id: 'tabellaHeaderPeriodo',
    defaultMessage: 'Periodo',
  },
  tabellaHeaderCompenso: {
    id: 'tabellaHeaderCompenso',
    defaultMessage: 'Compenso lordo',
  },
  tabellaHeaderCV: {
    id: 'tabellaHeaderCV',
    defaultMessage: 'CV',
  },
  tabellaHeaderAttestazioni: {
    id: 'tabellaHeaderAttestazioni',
    defaultMessage: 'Attestazioni di legge',
  },
  tabellaHeaderImporto: {
    id: 'tabellaHeaderImporto',
    defaultMessage: 'Importo liquidato',
  },
  tabellaHeaderEstremiLiquidazione: {
    id: 'tabellaHeaderEstremiLiquidazione',
    defaultMessage: 'Estremi atto di liquidazione',
  },
});
