import React from 'react';

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { searchContent, resetSearchContent } from '@plone/volto/actions';

import { readAsDataURL } from 'promise-file-reader';

import { defineMessages, useIntl } from 'react-intl';

import {
  Container,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Alert,
} from 'design-react-kit/dist/design-react-kit';
import { UniversalLink } from '@plone/volto/components';

import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';

import ResultTable from './Blocks/ResultTable';

import MessageContainer from '../../MessageContainer/MessageContainer';
import TablePagination from '../../TablePagination/TablePagination';

import { loadExcel } from '../../../actions/loadExcel/loadExcel';
import { resetState } from '../../../actions/resetState/resetState';

const IndirizzarioView = (props) => {
  const { content } = props;
  const intl = useIntl();

  //* utils
  const dispatch = useDispatch();
  const link = content['@id'];
  const path = props.location.pathname;

  // upload excel file
  const [isOpen, toggleModal] = useState(false);
  const [excelData, setExcelData] = useState('');
  const [loadedFile, setLoadedFile] = useState('');

  const onUpload = ({ target }) => {
    const file = target.files[0];
    setLoadedFile(file.name);

    readAsDataURL(file).then((data) => {
      const fields = data.match(/^data:(.*);(.*),(.*)$/);
      const fileData = fields[3];
      setExcelData(fileData);
    });
  };

  // manage state of upload excel file
  const loadExcelError = useSelector((state) => state.loadExcel.error);
  const loadExcelLoading = useSelector((state) => state.loadExcel.loading);
  const loadExcelLoaded = useSelector((state) => state.loadExcel.loaded);
  const loadExcelMessage = useSelector((state) => state.loadExcel.message);

  const loadExcelOnClick = () => {
    dispatch(loadExcel(path, excelData, '@upload-contacts'));
  };

  const resetUpload = () => {
    dispatch(resetState());
  };

  useEffect(() => {
    toggleModal(false);
    setLoadedFile('');
  }, [loadExcelLoaded]);

  useEffect(() => {
    return () => dispatch(resetState());
  }, [dispatch]);

  // contenuto tabella
  const contactItems = useSelector((state) => state.search.items);
  const totResults = useSelector((state) => state.search.total);

  // Redux - loading
  const isResultLoading = useSelector((state) => state.search.loading);

  // Redux - error

  const error = useSelector((state) => state.search.error);
  const noError = error === null;

  //* Pagination **/

  const [currentPage, setCurrentPage] = useState(1);
  const bSize = 2000;
  const bStart = (currentPage - 1) * bSize;

  //* Calcolo numero pagine

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totResults / bSize); i++) {
    pageNumbers.push(i);
  }

  const lastPage = pageNumbers.length;

  const lastResIndex = bSize * currentPage;

  const firstResIndex = bSize * (currentPage - 1) + 1;

  // Hook useSelector per accedere a store per ruolo utenti

  const userRole = useSelector((state) => state.users.user.roles);

  const addContentRole =
    userRole?.includes('Contributor') || userRole?.includes('Manager');

  //* Hook useEffect for contacts search

  useEffect(() => {
    dispatch(
      searchContent(path, {
        'path.depth': 1,

        b_size: bSize,
        b_start: bStart,
        sort_on: 'sortable_title',
        sort_order: 'ascending',
      }),
    );
    return () => {
      dispatch(resetSearchContent());
    };
  }, [dispatch, path, bSize, bStart, loadExcelLoaded]);

  return (
    <Container>
      <div className="rounded container file-manager-container">
        <div className="d-flex">
          {addContentRole && (
            <div className="add-file-container">
              <div className="add-file-item">
                <div className="icon-container">
                  <Icon icon="file-upload" />
                </div>
                <div className="link-container">
                  <UniversalLink href={`${link}/add?type=Contatto`}>
                    {intl.formatMessage(messages.addButton)}
                  </UniversalLink>
                </div>
              </div>
              <div className="add-file-item">
                <div className="icon-container">
                  <Icon icon="file-excel" />
                </div>
                <div className="link-container">
                  <a
                    href="/biblioteca-medica"
                    onClick={(event) => {
                      event.preventDefault();
                      toggleModal(!isOpen);
                    }}
                  >
                    {intl.formatMessage(messages.importFromExcel)}
                  </a>
                </div>
              </div>
            </div>
          )}
          <div className="add-file-item">
            <div className="icon-container">
              <Icon icon="file-download" />
            </div>
            <div className="link-container">
              <a href={`${path}/@@download/export-contacts`}>
                {intl.formatMessage(messages.exportExcel)}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="result-table-section">
        {loadExcelLoaded && (
          <Alert color="success">
            {intl.formatMessage(messages.importSuccessful)}. {loadExcelMessage}.
          </Alert>
        )}
        <div className="result-table-container">
          <ResultTable
            tableData={contactItems}
            messages={messages}
            addContentRole={addContentRole}
          />
        </div>

        {/* Mostra durante caricamento*/}
        {isResultLoading && <MessageContainer messageType="loading" />}
        {/* Mostra quando si verifica un errore */}
        {!noError && <MessageContainer messageType="error" />}
        <TablePagination
          searchResults={contactItems}
          firstResIndex={firstResIndex}
          lastResIndex={lastResIndex}
          totResults={totResults}
          noFiltersSet={true}
          pageNumbers={pageNumbers}
          currentPage={currentPage}
          lastPage={lastPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <div>
        <Modal
          isOpen={isOpen}
          toggle={() => toggleModal(!isOpen)}
          className="import-excel-modal"
        >
          <ModalHeader toggle={() => toggleModal(!isOpen)} id="esempio2">
            {intl.formatMessage(messages.importFromExcel)}
          </ModalHeader>
          <ModalBody>
            {!loadExcelError && !loadExcelLoading && (
              <div className="excel-modal-content">
                <div className="file-loader">
                  <label for="upload-file" className="ui btn button file">
                    {intl.formatMessage(messages.selectExcelFile)}
                    <input
                      id="upload-file"
                      type="file"
                      onChange={onUpload}
                      style={{ visibility: 'hidden' }}
                      accept=".xls,.xlsx"
                    />
                  </label>
                </div>
                {loadedFile !== '' && (
                  <div className="info-container">
                    <p>
                      <strong>
                        {intl.formatMessage(messages.selectedFile)}
                      </strong>{' '}
                      {loadedFile}
                    </p>
                  </div>
                )}
              </div>
            )}
            {loadExcelError && !loadExcelLoading && (
              <div>{intl.formatMessage(messages.uploadError)}</div>
            )}
            {loadExcelLoading && !loadExcelError && (
              <div style={{ textAlign: 'center' }} className="mb-3">
                {intl.formatMessage(messages.uploadLoading)}
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                if (loadExcelError != null) {
                  resetUpload();
                  toggleModal(!isOpen);
                } else {
                  toggleModal(!isOpen);
                }
              }}
            >
              Chiudi
            </Button>
            {loadExcelError === null && (
              <Button color="primary" onClick={() => loadExcelOnClick()}>
                Carica file
              </Button>
            )}
          </ModalFooter>
        </Modal>
      </div>
    </Container>
  );
};

export default IndirizzarioView;

const messages = defineMessages({
  addButton: {
    id: 'addButton',
    defaultMessage: 'Aggiungi un contatto',
  },
  importFromExcel: {
    id: 'importFromExcel',
    defaultMessage: 'Importa da Excel',
  },
  exportExcel: {
    id: 'exportExcel',
    defaultMessage: 'Esporta in formato Excel',
  },
  tabellaHeaderNominativo: {
    id: 'tabellaHeaderNominativo',
    defaultMessage: 'Nominativo',
  },
  tabellaHeaderSpecifica: {
    id: 'tabellaHeaderSpecifica',
    defaultMessage: 'Specifica',
  },
  tabellaHeaderIndirizzo: {
    id: 'tabellaHeaderIndirizzo',
    defaultMessage: 'Indirizzo',
  },
  tabellaHeaderCap: {
    id: 'tabellaHeaderCap',
    defaultMessage: 'Cap',
  },
  tabellaHeaderComune: {
    id: 'tabellaHeaderComune',
    defaultMessage: 'Comune',
  },
  tabellaHeaderProvincia: {
    id: 'tabellaHeaderProvincia',
    defaultMessage: 'Provincia',
  },
  tabellaHeaderUltimaModifica: {
    id: 'tabellaHeaderUltimaModifica',
    defaultMessage: 'Utente ultima mod.',
  },
  selectExcelFile: {
    id: 'selectExcelFile',
    defaultMessage: 'Seleziona file excel',
  },
  selectedFile: {
    id: 'selectedFile',
    defaultMessage: 'File selezionato:',
  },
  importSuccessful: {
    id: 'importSuccessful',
    defaultMessage: 'Il file excel è stato importato correttamente',
  },
  uploadError: {
    id: 'uploadError',
    defaultMessage: 'Si è verificato un errore nel caricamento del file',
  },
  uploadLoading: {
    id: 'uploadLoading',
    defaultMessage: 'Upload in corso...',
  },
});
