import React from 'react';
import redraft from 'redraft';

import config from '@plone/volto/registry';
import { UniversalLink } from '@plone/volto/components';
import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';

const View = (props) => {
  const { data } = props;
  const showTitle = data.show_block_title;

  return (
    <div className="full-width servizi-base-container">
      <div className="servizi-base-content">
        {showTitle && (
          <div className="title-section">
            <h4>
              {redraft(
                data.blockTitle,
                config.settings.richtextViewSettings.ToHTMLRenderers,
                config.settings.richtextViewSettings.ToHTMLOptions,
              )}
            </h4>
          </div>
        )}
        <div className="card-container">
          <div className="card card1">
            <div className="card-title-section">
              {data.icon1 !== undefined && (
                <div className="card-icon-container">
                  <Icon icon={data.icon1} />
                </div>
              )}
              <div className="card-title-container">
                {data.titleLink1?.length > 0 ? (
                  <h5>
                    <UniversalLink href={data.titleLink1[0]?.['@id']}>
                      {data.title1}
                    </UniversalLink>
                  </h5>
                ) : (
                  <h5>{data.title1}</h5>
                )}
              </div>
            </div>
            {data.card1 && (
              <div className="card-content-section">
                {redraft(
                  data.card1,
                  config.settings.richtextViewSettings.ToHTMLRenderers,
                  config.settings.richtextViewSettings.ToHTMLOptions,
                )}
              </div>
            )}
          </div>

          {data.title2 && (
            <div className="card card2">
              <div className="card-title-section">
                {data.icon2 !== undefined && (
                  <div className="card-icon-container">
                    <Icon icon={data.icon2} />
                  </div>
                )}
                <div className="card-title-container">
                  {data.titleLink2?.length > 0 ? (
                    <UniversalLink href={data.titleLink2[0]?.['@id']}>
                      {data.title2}
                    </UniversalLink>
                  ) : (
                    <h5>{data.title2}</h5>
                  )}
                </div>
              </div>

              <div className="card-content-section">
                {redraft(
                  data.card2,
                  config.settings.richtextViewSettings.ToHTMLRenderers,
                  config.settings.richtextViewSettings.ToHTMLOptions,
                )}
              </div>
            </div>
          )}

          {data.title3 && (
            <div className="card card3">
              <div className="card-title-section">
                {data.icon3 !== undefined && (
                  <div className="card-icon-container">
                    <Icon icon={data.icon3} />
                  </div>
                )}
                <div className="card-title-container">
                  {data.titleLink3?.length > 0 ? (
                    <UniversalLink href={data.titleLink3[0]?.['@id']}>
                      {data.title3}
                    </UniversalLink>
                  ) : (
                    <h5>{data.title3}</h5>
                  )}
                </div>
              </div>

              <div className="card-content-section">
                {redraft(
                  data.card3,
                  config.settings.richtextViewSettings.ToHTMLRenderers,
                  config.settings.richtextViewSettings.ToHTMLOptions,
                )}
              </div>
            </div>
          )}

          {data.title4 && (
            <div className="card card4">
              <div className="card-title-section">
                {data.icon4 !== undefined && (
                  <div className="card-icon-container">
                    <Icon icon={data.icon4} />
                  </div>
                )}
                <div className="card-title-container">
                  {data.titleLink4?.length > 0 ? (
                    <UniversalLink href={data.titleLink4[0]?.['@id']}>
                      {data.title4}
                    </UniversalLink>
                  ) : (
                    <h5>{data.title4}</h5>
                  )}
                </div>
              </div>

              <div className="card-content-section">
                {redraft(
                  data.card4,
                  config.settings.richtextViewSettings.ToHTMLRenderers,
                  config.settings.richtextViewSettings.ToHTMLOptions,
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default View;
