import React from 'react';
import { useIntl } from 'react-intl';

import { UniversalLink } from '@plone/volto/components';

const ResultTable = ({ tableData, messages }) => {
  const intl = useIntl();
  return (
    <div>
      {tableData?.length > 0 && (
        <table hover bsPrefix="table" className="py-10 gy-10">
          <thead>
            <tr>
              <th scope="col" className="px-5 col-3">
                <p>{intl.formatMessage(messages.tabellaHeaderStrutture)}</p>
              </th>
              <th scope="col" className="px-3 col-2">
                <p>{intl.formatMessage(messages.tabellaHeaderTipo)}</p>
              </th>
              <th scope="col" className="px-3 col-2">
                <p>{intl.formatMessage(messages.tabellaHeaderTitoloDoc)}</p>
              </th>
              <th scope="col" className="px-2 col-1">
                <p>{intl.formatMessage(messages.tabellaHeaderRevisione)}</p>
              </th>
              <th scope="col" className="px-3 col-2">
                <p>{intl.formatMessage(messages.tabellaHeaderData)}</p>
              </th>
              <th scope="col" className="px-2 col-3">
                <p>{intl.formatMessage(messages.tabellaHeaderAzione)}</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((singleData) => {
              const { structures, categories, title, revision, startDate } =
                singleData;
              return (
                <tr key={singleData['@id']}>
                  <td className="px-5 col-3">
                    <div>
                      {structures?.length > 1 ? (
                        <ul>
                          {structures?.map((structure, index) => (
                            <li key={index}>{structure}</li>
                          ))}
                        </ul>
                      ) : (
                        structures[0]
                      )}
                    </div>
                  </td>
                  <td className="px-3 col-2">
                    <p>{categories}</p>
                  </td>
                  <td className="px-3 col-2">
                    <UniversalLink href={singleData['@id']}>
                      {title}
                    </UniversalLink>
                  </td>
                  <td className="px-2 col-1">
                    <p>{revision}</p>
                  </td>
                  <td className="px-3 col-2">
                    <p>{startDate}</p>
                  </td>
                  <td className="px-2 col-3">
                    <UniversalLink
                      href={singleData['@id'] + '/@@download/file'}
                      className="button"
                      download
                    >
                      Scarica PDF
                    </UniversalLink>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ResultTable;
