import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { PagePlaceholderTitle } from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const ContattoView = ({ content, location }) => {
  const intl = useIntl();
  return (
    <div
      className="contatto-page-container ui container px-4"
      id="page-document"
    >
      <div className="contatto-page">
        <div className="page-header box-shadow PageHeaderWrapper mb-4">
          <div className="title-description-wrapper">
            <PagePlaceholderTitle content={content}>
              <h1 className="mb-3">{content?.title}</h1>
            </PagePlaceholderTitle>
          </div>
          <div className="contact-info">
            {content?.titolo && (
              <>
                <h4 className="label">
                  {intl.formatMessage(messages.titoloHeading)}
                </h4>
                <p
                  className="description nominativo"
                  style={{ textTransform: 'capitalize' }}
                >
                  {content?.titolo.toLowerCase()} {content?.title.toLowerCase()}
                </p>
              </>
            )}
            {content?.specifica && (
              <>
                <h4 className="label">
                  {intl.formatMessage(messages.specificaHeading)}
                </h4>
                <p className="description">{content?.specifica}</p>
              </>
            )}
            {content?.tipologia && (
              <>
                <h4 className="label">
                  {intl.formatMessage(messages.tipologiaHeading)}
                </h4>
                <p className="description">{content?.tipologia}</p>
              </>
            )}
            {content?.indirizzo && (
              <>
                <h4 className="label">
                  {intl.formatMessage(messages.indirizzoHeading)}
                </h4>
                <p className="description">{content?.indirizzo}</p>
              </>
            )}
            {content?.cap && content?.comune && content?.provincia && (
              <>
                <h4 className="label">
                  {intl.formatMessage(messages.luogoHeading)}
                </h4>
                <p className="description">
                  {content?.comune}, {content?.provincia}, {content?.cap}
                </p>
              </>
            )}
            {content?.email && (
              <>
                <h4 className="label">
                  {intl.formatMessage(messages.emailHeading)}
                </h4>
                <p className="description">{content?.email}</p>
              </>
            )}
            {content?.telefono && (
              <>
                <h4 className="label">
                  {intl.formatMessage(messages.telefonoHeading)}
                </h4>
                <p className="description">{content?.telefono}</p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContattoView;

const messages = defineMessages({
  titoloHeading: {
    id: 'titoloHeading',
    defaultMessage: 'Titolo',
  },
  specificaHeading: {
    id: 'specificaHeading',
    defaultMessage: 'Specifica',
  },
  indirizzoHeading: {
    id: 'indirizzoHeading',
    defaultMessage: 'Indirizzo',
  },
  luogoHeading: {
    id: 'luogoHeading',
    defaultMessage: 'Luogo',
  },
  tipologiaHeading: {
    id: 'tipologiaHeading',
    defaultMessage: 'Tipologia',
  },
  emailHeading: {
    id: 'emailHeading',
    defaultMessage: 'E-mail',
  },
  telefonoHeading: {
    id: 'telefonoHeading',
    defaultMessage: 'Telefono',
  },
  utenteUltimaModificaHeading: {
    id: 'utenteUltimaModificaHeading',
    defaultMessage: 'Utente Ultima Modifica',
  },
});
