const AlphabetNavbar = ({
  onClickHandler,
  filterLetter,
  resetLetterFilter,
}) => {
  const alphabet = [
    { id: 1, letter: 'a' },
    { id: 2, letter: 'b' },
    { id: 3, letter: 'c' },
    { id: 4, letter: 'd' },
    { id: 5, letter: 'e' },
    { id: 6, letter: 'f' },
    { id: 7, letter: 'g' },
    { id: 8, letter: 'h' },
    { id: 9, letter: 'i' },
    { id: 10, letter: 'l' },
    { id: 11, letter: 'm' },
    { id: 12, letter: 'n' },
    { id: 13, letter: 'o' },
    { id: 14, letter: 'p' },
    { id: 15, letter: 'q' },
    { id: 16, letter: 'r' },
    { id: 17, letter: 's' },
    { id: 18, letter: 't' },
    { id: 19, letter: 'u' },
    { id: 20, letter: 'v' },
    { id: 21, letter: 'z' },
  ];

  const selectLetter = (e) => {
    const selectedLetter = e.target.innerText.toLowerCase();
    onClickHandler(selectedLetter);

    if (selectedLetter === filterLetter) {
      resetLetterFilter();
    }
  };

  return (
    <div className="alphabet-navbar-container">
      {alphabet.map((element) => {
        const { id, letter } = element;

        const highlightButton = filterLetter === letter;

        return (
          <div className="navbar-button-container" key={id}>
            <button
              className={`${
                highlightButton ? 'navbar-item-active navbar-tab' : 'navbar-tab'
              }`}
              onClick={selectLetter}
            >
              <div className="letter-container">{letter.toUpperCase()}</div>
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default AlphabetNavbar;
