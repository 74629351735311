import React from 'react';

import { useIntl } from 'react-intl';

import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';
import { UniversalLink } from '@plone/volto/components';

const ResultTable = ({ tableData, messages, addContentRole }) => {
  const intl = useIntl();
  return (
    <div>
      {tableData.length > 0 && (
        <div className="table py-10 gy-10">
          <div className="row pt-3 head-row">
            <div className="px-5 col-4 column">
              <p>{intl.formatMessage(messages.tabellaHeaderTitolo)}</p>
            </div>
            <div className="px-5 col-4 column">
              <p>{intl.formatMessage(messages.tabellaHeaderAutore)}</p>
            </div>
            <div className="px-4 col-3 column">
              <p>{intl.formatMessage(messages.tabellaHeaderEdizione)}</p>
            </div>
            <div className="px-2 col-1 column"></div>
          </div>
          <div className="table-body">
            {tableData.map((singleData) => {
              const { title, autore, edizione, sottotitolo } = singleData;
              const link = singleData['@id'];
              return (
                <div className="row py-3" key={singleData['@id']}>
                  <div className="column px-5 col-4">
                    <UniversalLink href={link}>{title}</UniversalLink>
                    <p className="sottotitolo">{sottotitolo}</p>
                  </div>
                  <div className="column px-5 col-4">
                    <p>{autore}</p>
                  </div>
                  <div className="column px-4 col-3">
                    <p>{edizione}</p>
                  </div>
                  <div className="column px-2 col-1">
                    {addContentRole && (
                      <>
                        <UniversalLink href={`${link}/edit`}>
                          <Icon icon="edit" />
                        </UniversalLink>{' '}
                      </>
                    )}
                    {/* <Icon icon="trash-alt" /> */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default ResultTable;
