// customizzato blocco argomenti in evidenza
// per background immagine personalizzato
// - View

import React from 'react';
import PropTypes from 'prop-types';
import BodyWrapper from 'design-comuni-plone-theme/components/ItaliaTheme/Blocks/ArgumentsInEvidence/BodyWrapper';
import BottomBody from 'design-comuni-plone-theme/components/ItaliaTheme/Blocks/ArgumentsInEvidence/BottomBody';
import Block from 'design-comuni-plone-theme/components/ItaliaTheme/Blocks/ArgumentsInEvidence/Block';
import Background from 'design-comuni-plone-theme/components/ItaliaTheme/Blocks/ArgumentsInEvidence/Background';
import { useIntl } from 'react-intl';

const View = ({ data, id }) => {
  const currentIntl = useIntl();
  return (
    <div className="block full-width">
      <div className="argumentInEvidence">
        <Background image={data.image} />
        <BodyWrapper data={data} inEditMode={false}>
          {data.subblocks.map((subblock, index) => (
            <Block
              key={index}
              data={subblock}
              inEditMode={false}
              intl={currentIntl}
            />
          ))}
        </BodyWrapper>
        <BottomBody data={data} intl={currentIntl} />
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string.isRequired,
};

export default View;
