import React from 'react';
import { useIntl } from 'react-intl';

import { UniversalLink } from '@plone/volto/components';
import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';

const ResultTable = ({ tableData, messages }) => {
  const intl = useIntl();
  return (
    <div>
      {tableData.length > 0 && (
        <div className="table py-10 gy-10">
          <div className="row pt-3 head-row">
            <div className="column col-2 px-2">
              <p>{intl.formatMessage(messages.tabellaHeaderSoggetto)}</p>
            </div>
            <div className="column col-2 px-2">
              <p>
                {intl.formatMessage(messages.tabellaHeaderEstremiConferimento)}
              </p>
            </div>
            <div className="column col-2 px-2">
              <p>{intl.formatMessage(messages.tabellaHeaderOggetto)}</p>
            </div>
            <div className="column col-1 px-0">
              <p>{intl.formatMessage(messages.tabellaHeaderPeriodo)}</p>
            </div>
            <div className="column col-1 px-0">
              <p>{intl.formatMessage(messages.tabellaHeaderCompenso)}</p>
            </div>
            <div className="column col-1 px-0 text-align-center">
              <p>{intl.formatMessage(messages.tabellaHeaderCV)}</p>
            </div>
            <div className="column col-1 px-0">
              <p>{intl.formatMessage(messages.tabellaHeaderAttestazioni)}</p>
            </div>
            <div className="column col-1 px-1">
              <p>{intl.formatMessage(messages.tabellaHeaderImporto)}</p>
            </div>
            <div className="column col-1 px-0">
              <p>
                {intl.formatMessage(messages.tabellaHeaderEstremiLiquidazione)}
              </p>
            </div>
          </div>
          <div className="table-body">
            {tableData.map((data) => {
              const { Estremi, Oggetto, title } = data;
              return (
                <div className="row py-3" key={data['@id']}>
                  <div className="column col-2 px-2 py-4">{title}</div>
                  <div className="column col-2 px-2 py-4">{Estremi}</div>
                  <div className="column col-2 px-2 py-4">{Oggetto}</div>
                  <div className="column col-1 px-0 py-4">
                    10.04.2013 - 31.12.2018
                  </div>
                  <div className="column col-1 px-1 py-4">€ 2000.00</div>
                  <div className="column col-1 px-0 py-4 text-align-center">
                    <UniversalLink href="/">
                      <Icon icon="file-pdf" />
                    </UniversalLink>
                  </div>
                  <div className="column col-1 px-0 py-4 text-align-center">
                    <UniversalLink href="/">
                      <Icon icon="file-pdf" />
                    </UniversalLink>
                  </div>
                  <div className="column col-1 px-1 py-4">€ 549.00</div>
                  <div className="column col-1 px-0 py-4">554 - 19.07.2017</div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default ResultTable;
