import React from 'react';

import Body from './Body';

const View = (props) => {
  return (
    <div className="biblioteca-medica-container bandi-search mb-4">
      <Body {...props} />
    </div>
  );
};

export default View;
