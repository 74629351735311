import React from 'react';

import { TextEditorWidget } from 'design-comuni-plone-theme/components/ItaliaTheme';

const SubsectionTitleEdit = (props) => {
  const { block, data, onChangeHandler, fieldName, className } = props;

  return (
    <div className={className}>
      <TextEditorWidget
        data={data}
        selected={true}
        fieldName={fieldName}
        block={block}
        onChangeBlock={(v) => {
          onChangeHandler(block, {
            ...data,
            title1: v.title1,
            title2: v.title2,
          });
        }}
        className={className}
      />
    </div>
  );
};

export default SubsectionTitleEdit;
