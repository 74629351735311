import React from 'react';

import { getBaseUrl } from '@plone/volto/helpers';
import { SidebarPortal } from '@plone/volto/components';

import RicercaModuliData from './Data';

import Body from './Body';

const Edit = (props) => {
  const { data, block, onChangeBlock, selected, pathname, properties } = props;
  return (
    <div className="bandi-search public-ui ricerca-moduli">
      <Body
        data={data}
        path={getBaseUrl(pathname)}
        inEditMode={true}
        onChangeBlock={onChangeBlock}
        properties={properties}
      />

      <SidebarPortal selected={selected}>
        <RicercaModuliData
          key={block}
          {...props}
          data={data}
          block={block}
          onChangeBlock={onChangeBlock}
        />
      </SidebarPortal>
    </div>
  );
};

export default Edit;
