import React from 'react';

const NavBar = ({
  alphabet,
  onClickHandler,
  filterLetter,
  resetLetterFilter,
}) => {
  return (
    <div className="navbar-container">
      {alphabet.map((element) => {
        const { id, letter } = element;

        const highlightButton = filterLetter === letter;

        return (
          <div className="navbar-button-container" key={id}>
            <button
              className={`${
                highlightButton ? 'navbar-item-active navbar-tab' : 'navbar-tab'
              }`}
              key={id}
              onClick={() => {
                onClickHandler(letter);
                if (filterLetter === letter) {
                  resetLetterFilter();
                }
              }}
            >
              <div className="letter-container">{letter.toUpperCase()}</div>
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default NavBar;
