import React from 'react';

import { useIntl } from 'react-intl';

import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';
import { UniversalLink } from '@plone/volto/components';

const ResultTable = ({ tableData, messages, addContentRole }) => {
  const intl = useIntl();
  return (
    <div>
      {tableData.length > 0 && (
        <div className="table py-10 gy-10 mt-5">
          <div className="row pt-3 head-row">
            <div className="px-3 col-2 column">
              <p>{intl.formatMessage(messages.tabellaHeaderNominativo)}</p>
            </div>
            <div className="px-3 col-3 column">
              <p>{intl.formatMessage(messages.tabellaHeaderSpecifica)}</p>
            </div>
            <div className="px-3 col-2 column">
              <p>{intl.formatMessage(messages.tabellaHeaderIndirizzo)}</p>
            </div>
            <div className="px-3 col-1 column">
              <p>{intl.formatMessage(messages.tabellaHeaderCap)}</p>
            </div>
            <div className="px-3 col-1 column">
              <p>{intl.formatMessage(messages.tabellaHeaderComune)}</p>
            </div>
            <div className="px-3 col-1 column">
              <p>{intl.formatMessage(messages.tabellaHeaderProvincia)}</p>
            </div>
            <div className="px-3 col-1 column">
              <p>{intl.formatMessage(messages.tabellaHeaderUltimaModifica)}</p>
            </div>
            <div className="px-3 col-1 column"></div>
          </div>
          <div className="table-body">
            {tableData.map((singleData) => {
              const {
                title,
                specifica,
                cap,
                comune,
                provincia,
                indirizzo,
                modified_by,
              } = singleData;
              const link = singleData['@id'];
              return (
                <div className="row py-3" key={singleData['@id']}>
                  <div className="column px-3 col-2">
                    <UniversalLink href={link}>{title}</UniversalLink>
                  </div>
                  <div className="column px-3 col-3">
                    <p>{specifica}</p>
                  </div>
                  <div className="column px-3 col-2">
                    <p>{indirizzo}</p>
                  </div>
                  <div className="column px-3 col-1">
                    <p>{cap}</p>
                  </div>
                  <div className="column px-3 col-1">
                    <p>{comune}</p>
                  </div>
                  <div className="column px-3 col-1">
                    <p>{provincia}</p>
                  </div>
                  <div className="column px-3 col">
                    <p>{modified_by}</p>
                  </div>
                  <div className="column px-3 col">
                    {addContentRole && (
                      <>
                        <UniversalLink href={`${link}/edit`}>
                          <Icon icon="edit" />
                        </UniversalLink>{' '}
                      </>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default ResultTable;
