/**
 * Faq actions.
 * @module actions/loadExcel/loadExcel
 */

import { LOAD_EXCEL } from '../../constants/ActionTypes';

/**
 * Get FAQ items.
 * @function loadExcel
 * @returns {Object} Faq action.
 */

export function loadExcel(path, data, callType) {
  return {
    type: LOAD_EXCEL,
    request: {
      op: 'post',
      path: `${path}/${callType}`,
      data: { data: data },
    },
  };
}

//@upload-books
