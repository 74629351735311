import React from 'react';

import { UniversalLink } from '@plone/volto/components';
import { TextEditorWidget } from 'design-comuni-plone-theme/components/ItaliaTheme/manage/Widgets/TextEditorWidget';
import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';

const CardEdit = (props) => {
  const {
    block,
    data,
    onChangeHandler,
    fieldName,
    className,
    icon,
    title,
    titleLink,
  } = props;
  return (
    <div className={`card ${className}`}>
      <div className="card-title-section">
        {icon !== undefined && (
          <div className="card-icon-container">
            <Icon icon={icon} />
          </div>
        )}
        <div className="card-title-container">
          {titleLink?.length > 0 ? (
            <h5>
              <UniversalLink href={titleLink[0]?.['@id']}>
                {title}
              </UniversalLink>
            </h5>
          ) : (
            <h5>{title}</h5>
          )}
          {!title && (
            <h5 className="placeholder">Inserisci un titolo nella sidebar</h5>
          )}
        </div>
      </div>
      <div className="card-content-section">
        <TextEditorWidget
          data={data}
          fieldName={fieldName}
          block={block}
          onChangeBlock={(v) => {
            onChangeHandler(block, {
              ...data,
              card1: v.card1,
              card2: v.card2,
              card3: v.card3,
              card4: v.card4,
            });
          }}
          className={`"text-editor ${className}"`}
        />
      </div>
    </div>
  );
};

export default CardEdit;
