import React from 'react';
import { useState, useEffect } from 'react';

import { UniversalLink } from '@plone/volto/components';
import { Container } from 'design-react-kit/dist/design-react-kit';
import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';

const ContactCard = (props) => {
  const { data } = props;
  const [contactIconValue, setContactIconValue] = useState('');
  useEffect(() => {
    setContactIconValue(data.contactIcon);
  }, [data.contactIcon]);

  return (
    <div className="contact-subsection-content contact-card-subsection">
      <Container>
        {data.title && (
          <div className="card-container">
            <div className="card-content">
              <div className="text-content">
                <h4>{data.title}</h4>
                <p>{data.info}</p>
              </div>
              {data.contact1 && (
                <div className="contact-info-grid">
                  <div class="contact-grid-icon">
                    <Icon className="contact-icon" icon="it-telephone" />
                  </div>
                  <div className="contact-grid-text">
                    <p>{data.contact1}</p>
                  </div>
                </div>
              )}
              {data.contact2 && (
                <div className="contact-info-grid">
                  <div class="contact-grid-icon">
                    <Icon className="contact-icon" icon="it-mail" />
                  </div>
                  <div className="contact-grid-text">
                    <a href={`"mailto:${data.contact2}"`}>{data.contact2}</a>
                  </div>
                </div>
              )}
              {data.contact3 && (
                <div className="contact-info-grid">
                  <div class="contact-grid-icon">
                    {contactIconValue === data.contactIcon && (
                      <Icon className="contact-icon" icon={contactIconValue} />
                    )}
                  </div>
                  <div className="contact-grid-text">
                    <p>{data.contact3}</p>
                  </div>
                </div>
              )}
              {data.contactLink && (
                <div className="link-section">
                  <UniversalLink href={data.contactLink[0]?.['@id']}>
                    Modulo di contatto diretto
                    <Icon className="arrow-icon" icon="it-arrow-right" />
                  </UniversalLink>
                </div>
              )}
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

export default ContactCard;
