import React from 'react';
import { Container, Row, Col } from 'design-react-kit/dist/design-react-kit';

import IconsList from './Blocks/IconsList/IconsList';
import ContactCard from './Blocks/ContactCard/ContactCard';
import SubsectionTitle from './Blocks/SubsectionTitle/SubsectionTitle';

const View = (props) => {
  const { data } = props;
  return (
    <div className="full-width contact-info-container">
      <Container className="py-5">
        <Row>
          <Col md="12" lg="6">
            <div className="contact-info-content container">
              <SubsectionTitle props={props} title={data.title1} {...props} />
              <IconsList {...props} />
            </div>
          </Col>
          <Col md="12" lg="6">
            <div className="contact-info-content contact-card container">
              <SubsectionTitle props={props} title={data.title2} {...props} />
              <ContactCard {...props} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default View;
