/**
 * Add your config changes here.
 * @module config
 */

import applyItaliaConfig from 'design-comuni-plone-theme/config/italiaConfig';
import '@plone/volto/config';

// WIDGETS
import { defaultIconWidgetOptions } from 'design-comuni-plone-theme/helpers/index';

// BASIC COMPONENTS
import {
  PersonaRuolo,
  PersonaContatti,
  PersonaDocumenti,
  PersonaUlterioriInformazioni,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

// COMPONENTS
import {
  /** BLOCKS **/
  ComeRaggiungerciEdit,
  ComeRaggiungerciView,
  ServiziBaseEdit,
  ServiziBaseView,
  GalleriaNeonatiEdit,
  GalleriaNeonatiView,
  RicercaModuliEdit,
  RicercaModuliView,
  SpecialistiEdit,
  SpecialistiView,
  BibliotecaMedicaEdit,
  BibliotecaMedicaView,
  RicercaAlfabeticaTemplate,
  AddRicercaAlfabeticaTemplate,

  /** VIEWS **/
  PatologieView,
  IncarichiView,
  DocumentoQualitaView,
  LibroView,
  IndirizzarioView,
  PersonaReparto,
  PersonaDipartimento,
  ContattoView,
  PatologiaView,
} from '@package/components';

import fieldSchema from './customizations/volto-form-block/components/fieldSchema';

import View from '@package/customizations/volto-form-block/components/View';
import Edit from '@package/customizations/volto-form-block/components/Edit';
import IconWidget from 'design-comuni-plone-theme/components/ItaliaTheme/manage/Widgets/IconWidget';

//ICONS
import infoSVG from '@plone/volto/icons/info.svg';
import packSVG from '@plone/volto/icons/pack.svg';
import babySVG from 'design-comuni-plone-theme/icons/fontawesome-free-5.15.4-web/svgs/solid/baby.svg';
import fileSVG from 'design-comuni-plone-theme/icons/fontawesome-free-5.15.4-web/svgs/regular/file-alt.svg';
import doctorSVG from 'design-comuni-plone-theme/icons/fontawesome-free-5.15.4-web/svgs/solid/user-md.svg';
import bookSVG from 'design-comuni-plone-theme/icons/fontawesome-free-5.15.4-web/svgs/solid/book-medical.svg';

const additionalIcons = [
  ...defaultIconWidgetOptions,
  ['phone-alt', 'Telefono'],
  ['hospital-alt', 'Ospedale'],
  ['stethoscope', 'Stetoscopio'],
  ['file-medical-alt', 'Referti'],
  ['utentils', 'Alimentazione'],
];

export default function applyConfig(voltoConfig) {
  let config = applyItaliaConfig(voltoConfig);

  config.settings = {
    ...config.settings,
    // italiaThemeViewsConfig: {
    //   ...config.settings.italiaThemeViewsConfig,
    //   imagePosition: 'afterHeader', // possible values: afterHeader, documentBody
    // },
    siteProperties: {
      /*
        Per tutte le props di siteProperties, può essere definito un oggetto per i siti multilingua, strutturato in questo modo:
        {'default': _valore_della_prop, 'it': _valore_della_prop,...}
        Se l'oggetto non è strutturato in questo modo, viene usato l'oggetto stesso, altrimenti viene preso il valore della corrispondente alla prop della lingua corrente o alla prop 'default' se esiste.
      */
      ...config.settings.siteProperties,
      siteTitle: 'IBMDR',
      siteSubtitle: 'Registro italiano Donatori Midollo Osseo',
      parentSiteTitle: 'Ente Ospedaliero - Ospedali Galliera di Genova',
      parentSiteURL: 'https://www.galliera.it/',
      subsiteParentSiteTitle: 'Ente Ospedaliero - Ospedali Galliera di Genova',
      arLoginUrl: 'https://ibmdr.galliera.it/area-riservata/login',
      arLogoutUrl: '/logout',
    },
    loadables: {
      ...config.settings.loadables,
    },
  };

  // config.widgets = {
  //   ...config.widgets,
  // };

  //config.blocks.blocksConfig.listing.variations = [
  //  ...config.blocks.blocksConfig.listing.variations,
  //];
  config.views = {
    ...config.views,
    layoutViews: {
      ...config.views.layoutViews,
      incarichi_view: IncarichiView,
      patologie_view: PatologieView,
    },
    contentTypesViews: {
      ...config.views.contentTypesViews,
      DocumentoQualita: DocumentoQualitaView,
      Libro: LibroView,
      Rubrica: IndirizzarioView,
      Contatto: ContattoView,
      Patologia: PatologiaView,
    },
    layoutViewsNamesMapping: {
      ...config.views.layoutViewsNamesMapping,
      incarichi_view: 'Incarichi',
      patologie_view: 'Patologie',
    },
  };

  config.blocks.blocksConfig.raggiungere = {
    id: 'raggiungere',
    title: 'Contatti',
    icon: infoSVG,
    group: 'common',
    view: ComeRaggiungerciView,
    edit: ComeRaggiungerciEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  config.blocks.blocksConfig.servizibase = {
    id: 'servizibase',
    title: 'Servizi Base',
    icon: packSVG,
    group: 'common',
    view: ServiziBaseView,
    edit: ServiziBaseEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };

  config.blocks.blocksConfig.gallerianeonati = {
    id: 'gallerianeonati',
    title: 'Galleria neonati',
    icon: babySVG,
    group: 'media',
    view: GalleriaNeonatiView,
    edit: GalleriaNeonatiEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  config.blocks.blocksConfig.ricercamoduli = {
    id: 'ricercamoduli',
    title: 'Ricerca moduli',
    icon: fileSVG,
    group: 'common',
    view: RicercaModuliView,
    edit: RicercaModuliEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  config.blocks.blocksConfig.specialisti = {
    id: 'specialisti',
    title: 'Specialisti',
    icon: doctorSVG,
    group: 'common',
    view: SpecialistiView,
    edit: SpecialistiEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };

  config.blocks.blocksConfig.biblioteca = {
    id: 'biblioteca',
    title: 'Biblioteca',
    icon: bookSVG,
    group: 'common',
    view: BibliotecaMedicaView,
    edit: BibliotecaMedicaEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };

  config.blocks.blocksConfig.form = {
    ...config.blocks.blocksConfig.form,
    view: View,
    edit: Edit,
    fieldSchema: fieldSchema,
  };

  config.blocks.blocksConfig.listing = {
    ...config.blocks.blocksConfig.listing,
    variations: [
      ...config.blocks.blocksConfig.listing.variations,
      {
        id: 'bloccoAlfabeto',
        isDefault: false,
        title: 'Ricerca alfabetica',
        template: RicercaAlfabeticaTemplate,
        schemaEnhancer: ({ schema, formData, intl }) => {
          AddRicercaAlfabeticaTemplate(schema, formData, intl);
          // addDefaultOptions(schema, formData, intl);
          return schema;
        },
      },
    ],
  };

  config.settings.italiaThemeViewsConfig['Persona'] = {
    sections: [
      { /* RUOLO */ component: PersonaRuolo },
      { /* CONTATTI */ component: PersonaContatti },
      { /* DOCUMENTI */ component: PersonaDocumenti },
      { /* REPARTO */ component: PersonaReparto },
      { /* DIPARTIMENTO */ component: PersonaDipartimento },
      { /* ULTERIORI INFORMAZIONI */ component: PersonaUlterioriInformazioni },
    ],
  };

  config.widgets.widget.icon = (props) => (
    <IconWidget {...props} defaultOptions={additionalIcons} />
  );

  config.settings['volto-gdpr-privacy'].defaultPanelConfig.text = {
    it: {
      title: 'Usiamo i cookies',
      description:
        "Questo sito utilizza i cookie tecnici di navigazione e di sessione per garantire un miglior servizio di navigazione del sito, e cookie analitici per raccogliere informazioni sull'uso del sito da parte degli utenti.  Utilizza anche cookie di profilazione dell'utente per fini statistici. I cookie di profilazione puoi decidere se abilitarli o meno cliccando sul pulsante 'Cambia le impostazioni'. Per saperne di più su come disabilitare i cookie oppure abilitarne solo alcuni, consulta la nostra <a href='https://www.galliera.it/20/56/53/54/54' target='_blank'>Cookie Policy.</a>",
    },
  };

  config.settings['volto-gdpr-privacy'].defaultPanelConfig.technical.choices = [
    ...config.settings[
      'volto-gdpr-privacy'
    ].defaultPanelConfig.technical.choices.filter(
      (f) => f.config_key !== 'GANALYTICS' && f.config_key !== 'GRECAPTCHA',
    ),
  ];

  config.settings['volto-gdpr-privacy'].defaultPanelConfig.profiling.choices = [
    ...config.settings[
      'volto-gdpr-privacy'
    ].defaultPanelConfig.profiling.choices.filter(
      (f) => f.config_key !== 'VIMEO',
    ),
  ];
  // TBD
  // config.settings.headerslimTertiaryMenu = {
  //   it: [
  //     { title: 'Form di contatto', url: '/contatti' },
  //     //{ title: 'Novità', url: '/it/novita' },
  //   ],
  //   en: [
  //     // { title: 'Contacts', url: '/en/contacts' },
  //     //{ title: 'News', url: '/en/news' },
  //   ],
  // };

  return config;
}
