import React from 'react';

import View from './View';

const Edit = (props) => {
  return (
    <div>
      <View {...props} />
    </div>
  );
};

export default Edit;
