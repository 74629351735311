import React from 'react';
import PropTypes from 'prop-types';

import { SidebarPortal } from '@plone/volto/components';

import Block from './Block';
import Sidebar from './Sidebar';

const Edit = (props, focusOn, onChange) => {
  const { block, data, onChangeBlock, selected } = props;

  return (
    <div className="full-width container specialisti">
      <div className="specialisti-block-container container edit-container">
        <div className="specialisti-block-title container">
          <h3>{data.text}</h3>
        </div>
        <div className="specialisti-block-content container edit-container">
          <Block data={data} />
        </div>
      </div>

      <SidebarPortal selected={selected}>
        {/* <SidebarPortal selected={this.props.selected || false}> */}
        <Sidebar
          key={block}
          {...props}
          data={data}
          block={block}
          onChangeBlock={onChangeBlock}
        />
      </SidebarPortal>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Edit.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string.isRequired,
  block: PropTypes.string.isRequired,
  selected: PropTypes.any,
  intl: PropTypes.any,
  onChangeBlock: PropTypes.func.isRequired,
};
export default Edit;
