import React from 'react';

import { defineMessages, useIntl } from 'react-intl';

const MessageContainer = ({ messageType }) => {
  const intl = useIntl();

  return (
    <div className="message-container">
      {messageType === 'loading' && (
        <p>{intl.formatMessage(messages.loading)}</p>
      )}
      {messageType === 'error' && (
        <p>{intl.formatMessage(messages.loadingError)}</p>
      )}
      {messageType === 'no-res' && (
        <p>{intl.formatMessage(messages.noResults)}</p>
      )}
    </div>
  );
};

export default MessageContainer;

const messages = defineMessages({
  noResults: {
    id: 'noResults',
    defaultMessage: 'La ricerca non ha prodotto nessun risultato',
  },
  loading: {
    id: 'loading',
    defaultMessage: 'Caricamento risultati...',
  },
  loadingError: {
    id: 'loadingError',
    defaultMessage: 'Si è verificato un errore durante il caricamento',
  },
});
