import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import { Segment, Accordion } from 'semantic-ui-react';

import {
  TextWidget,
  CheckboxWidget,
  ObjectBrowserWidget,
} from '@plone/volto/components';
import IconWidget from 'design-comuni-plone-theme/components/ItaliaTheme/manage/Widgets/IconWidget';
import { defaultIconWidgetOptions } from 'design-comuni-plone-theme/helpers/index';

const Sidebar = ({
  data,
  block,
  onChangeBlock,
  onChangeSubBlock,
  selected = 0,
  setSelected,
  openObjectBrowser,
}) => {
  const intl = useIntl();

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage
            id="servizi-base-block"
            defaultMessage="Servizi Base"
          />
        </h2>
      </header>
      <Segment>
        <CheckboxWidget
          id="show_block_title"
          title={intl.formatMessage(messages.show_block_title)}
          value={data.show_block_title ? data.show_block_title : false}
          onChange={(id, value) => {
            onChangeBlock(block, {
              ...data,
              [id]: value,
            });
          }}
        />
      </Segment>
      <Accordion fluid styled className="form">
        <Accordion.Title active={true} index={0} onClick={() => {}}>
          Card 1
        </Accordion.Title>
        <Accordion.Content active={true}>
          <IconWidget
            id="icon1"
            value={data.icon1 ?? ''}
            defaultOptions={defaultIconWidgetOptions}
            onChange={(name, value) => {
              onChangeBlock(block, { ...data, [name]: value });
            }}
          />
          <TextWidget
            id="title1"
            title={intl.formatMessage(messages.enter_title)}
            value={data.title1}
            onChange={(name, value) => {
              onChangeBlock(block, { ...data, [name]: value });
            }}
            description="Max. 22 caratteri se viene impostata un'icona, max. 25 caratteri senza icona"
          />
          <ObjectBrowserWidget
            id="titleLink1"
            title={intl.formatMessage(messages.card_title_link)}
            description=""
            required={false}
            widgetOptions={{
              pattern_options: {
                maximumSelectionSize: 1,
              },
            }}
            value={data.titleLink1 ?? []}
            onChange={(id, value) =>
              onChangeBlock(block, { ...data, [id]: value })
            }
          />
        </Accordion.Content>
      </Accordion>
      <Accordion fluid styled className="form">
        <Accordion.Title active={true} index={1} onClick={() => {}}>
          Card 2
        </Accordion.Title>
        <Accordion.Content active={true}>
          <IconWidget
            id="icon2"
            value={data.icon2 ?? ''}
            defaultOptions={defaultIconWidgetOptions}
            onChange={(name, value) => {
              onChangeBlock(block, { ...data, [name]: value });
            }}
          />
          <TextWidget
            id="title2"
            title={intl.formatMessage(messages.enter_title)}
            value={data.title2}
            onChange={(name, value) => {
              onChangeBlock(block, { ...data, [name]: value });
            }}
            description="Max. 22 caratteri se viene impostata un'icona, max. 25 caratteri senza icona"
          />
          <ObjectBrowserWidget
            id="titleLink2"
            title={intl.formatMessage(messages.card_title_link)}
            description=""
            required={false}
            widgetOptions={{
              pattern_options: {
                maximumSelectionSize: 1,
              },
            }}
            value={data.titleLink2 ?? []}
            onChange={(id, value) =>
              onChangeBlock(block, { ...data, [id]: value })
            }
          />
        </Accordion.Content>
      </Accordion>
      <Accordion fluid styled className="form">
        <Accordion.Title active={true} index={2} onClick={() => {}}>
          Card 3
        </Accordion.Title>
        <Accordion.Content active={true}>
          <IconWidget
            id="icon3"
            value={data.icon3 ?? ''}
            defaultOptions={defaultIconWidgetOptions}
            onChange={(name, value) => {
              onChangeBlock(block, { ...data, [name]: value });
            }}
          />
          <TextWidget
            id="title3"
            title={intl.formatMessage(messages.enter_title)}
            value={data.title3}
            onChange={(name, value) => {
              onChangeBlock(block, { ...data, [name]: value });
            }}
            description="Max. 22 caratteri se viene impostata un'icona, max. 25 caratteri senza icona"
          />
          <ObjectBrowserWidget
            id="titleLink3"
            title={intl.formatMessage(messages.card_title_link)}
            description=""
            required={false}
            widgetOptions={{
              pattern_options: {
                maximumSelectionSize: 1,
              },
            }}
            value={data.titleLink3 ?? []}
            onChange={(id, value) =>
              onChangeBlock(block, { ...data, [id]: value })
            }
          />
        </Accordion.Content>
      </Accordion>
      <Accordion fluid styled className="form">
        <Accordion.Title active={true} index={3} onClick={() => {}}>
          Card 4
        </Accordion.Title>
        <Accordion.Content active={true}>
          <IconWidget
            id="icon4"
            value={data.icon4 ?? ''}
            defaultOptions={defaultIconWidgetOptions}
            onChange={(name, value) => {
              onChangeBlock(block, { ...data, [name]: value });
            }}
          />
          <TextWidget
            id="title4"
            title={intl.formatMessage(messages.enter_title)}
            value={data.title4}
            onChange={(name, value) => {
              onChangeBlock(block, { ...data, [name]: value });
            }}
            description="Max. 22 caratteri se viene impostata un'icona, max. 25 caratteri senza icona"
          />
          <ObjectBrowserWidget
            id="titleLink4"
            title={intl.formatMessage(messages.card_title_link)}
            description=""
            required={false}
            widgetOptions={{
              pattern_options: {
                maximumSelectionSize: 1,
              },
            }}
            value={data.titleLink4 ?? []}
            onChange={(id, value) =>
              onChangeBlock(block, { ...data, [id]: value })
            }
          />
        </Accordion.Content>
      </Accordion>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  block: PropTypes.string,
  onChangeBlock: PropTypes.func,
  selected: PropTypes.any,
  setSelected: PropTypes.func,
};

export default Sidebar;

const messages = defineMessages({
  enter_title: {
    id: 'enter_title',
    defaultMessage: 'Inserisci il titolo per la card',
  },
  show_block_title: {
    id: 'show_block_title',
    defaultMessage: 'Mostra il titolo del blocco',
  },
  card_title_link: {
    id: 'card_title_link',
    defaultMessage: 'Scegli una pagina a cui collegare il titolo della card',
  },
});
